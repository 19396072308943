import extractForm from '../extractForm'
import React from 'react';
import API from '../ApiService';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import UploadFile from "../upload-file/UploadFile";
import Select from "../form/Select";
import PopUp from "../popup/PopUp";
import './application-templates.css'
import DatePicker from "../calendar/DatePicker";


export default class Refund extends React.Component {
    state = {
        me: [],
        agreements: [],
        requisites: [],
        files: {},
        isSuccessOpen: false,
    };

    componentWillMount() {
        API.me().then(data => {
                this.setState({me: data});
            });

        API.agreements().then(data => {
            this.setState({
                agreements: data,
                agreementDate: moment(data[0]['dateStart'], "DD.MM.YYYY").toDate(),
            })
        });

        API.requisites().then(data => {
            this.setState({requisites: data});
        });
    }

    handleChangeAgreement = (e, {value}) => {
        this.setState({
            agreementDate: moment(this.state.agreements[value]['dateStart'], "DD.MM.YYYY").toDate()
        });
    };

    handleChangeAgreementDate = (date) => {
        this.setState({
            agreementDate: date
        });
    };

    setFile = (value, id) => {
        let files = this.state.files;

        files[id] = value;

        this.setState({
            files: files
        })
    };

    createReturnMoney = (e) => {
        e.preventDefault();

        let {valid, inputs} = extractForm(e.target.id);

        if (valid) {
            API.createReturnMoney(
                inputs['org'],
                inputs['name'],
                inputs['sum'],
                inputs['inn'],
                inputs['kpp'],
                inputs['bik'],
                inputs['bankName'],
                inputs['bankKorAccount'],
                inputs['bankAccount'],
                inputs['remittee'],
            ).then(data => {
                window.open(data.urlFile);
            })
        }
    };

    sendMessage = (e) => {
        e.preventDefault();

        let {inputs} = extractForm('createReturnMoney');

        API.sendMail(
            inputs['text'],
            e.target.id,
            Object.values(this.state.files)
        ).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
        });
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {

        let agreements = this.state.agreements.map((item, index) => {
            return {key: index, text: item['agreementName'], value: index}
        });

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h1>Заявка на возврат средств</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <form className="box" encType="multipart/form-data" id="createReturnMoney" onSubmit={this.createReturnMoney}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <label>Контактное лицо</label>
                                        <input type="text" required name="name"
                                               defaultValue={this.state.me.clientContact}/>
                                    </div>
                                    <div className="col-md">
                                        <label>Договор</label>
                                        <Select
                                            options={agreements}
                                            defaultValue={0}
                                            inputName='agreement'
                                            onChange={this.handleChangeAgreement}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <label>Дата заключения договора</label>
                                        <DatePicker
                                            name="agreementDate"
                                            required
                                            readOnly
                                            onChange={this.handleChangeAgreementDate}
                                            date={this.state.agreementDate}
                                        />
                                    </div>
                                    <div className="col-md">
                                        <label>Сумма</label>
                                        <input type="text" required name="sum"
                                               placeholder="0,00"
                                               pattern="\d+[.,]?\d{0,2}"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label>Организация</label>
                                        <input type="text" required name="org"
                                               defaultValue={this.state.me.clientName}/>
                                    </div>
                                    <div className="col-lg-6">
                                        <label>Текст письма</label>
                                        <input type="text" required name="text"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm">
                                        <h2>Реквизиты</h2>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md">
                                        <label>ИНН</label>
                                        <input type="text" required name="inn"
                                               defaultValue={this.state.requisites.inn}/>
                                    </div>
                                    <div className="col-md">
                                        <label>КПП</label>
                                        <input type="text" required name="kpp"
                                               defaultValue={this.state.requisites.kpp}/>
                                    </div>
                                    <div className="col-md">
                                        <label>БИК</label>
                                        <input type="text" required name="bik"
                                               defaultValue={this.state.requisites.bik}/>
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Кор. счет</label>
                                        <input type="text" required name="bankKorAccount"
                                               defaultValue={this.state.requisites.bankKorAccount}/>
                                    </div>
                                    <div className="col-lg-3">
                                        <label>Расчётный счет</label>
                                        <input type="text" required name="bankAccount"
                                               defaultValue={this.state.requisites.bankAccount}/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <label>Банк</label>
                                        <input type="text" required name="bankName"
                                               defaultValue={this.state.requisites.bankName}/>
                                    </div>
                                    <div className="col-md-5">
                                        <label>Получатель</label>
                                        <input type="text" required name="remittee"
                                               defaultValue={this.state.me.clientName}/>
                                    </div>
                                    <div className="col-md">
                                        <button className="button button_medium button_right">
                                            Сохранить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <form className="box" encType="multipart/form-data" id="refund" onSubmit={this.sendMessage}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg">
                                        <UploadFile label="Отсканируйте&nbsp;и&nbsp;прикрепите подписанное заявление"
                                                    id="statementFile"
                                                    modifier="box"
                                                    required
                                                    onChange={this.setFile}
                                        />
                                    </div>
                                    <div className="col-lg">
                                        <UploadFile label="Отсканируйте&nbsp;и&nbsp;прикрепите акт сверки"
                                                    id="actFile"
                                                    modifier="box"
                                                    required
                                                    onChange={this.setFile}
                                        />
                                    </div>
                                </div>
                                <div className="row callback-additional">
                                    <div className="col-lg col-callback">
                                        <label>
                                            Наш менеджер перезвонит вам по номеру:
                                        </label>
                                        <input name="phone" defaultValue={this.state.me.clientPhone} type="hidden"/>
                                        <div className="phone" style={{margin: '15px 0 29px 0'}}>
                                            {this.state.me.clientPhone}
                                        </div>
                                    </div>
                                    <div className="col-lg additionalPhone" style={{paddingTop: '20px'}}>
                                        <label>Номер для оперативной связи</label>
                                        <input type="tel" name="additionalPhone"
                                               pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                                               placeholder="+7 (999) 123-45-67"
                                               style={{maxWidth: '200px'}}
                                        />

                                        <button className="button button_medium button_right" style={{marginTop: '-5px'}}>
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваша заявка на возврат средств принята</div>
                    <div className="button button_big" onClick={this.closeSuccess}>Ok</div>
                </PopUp>
            </div>
        )
    }
};