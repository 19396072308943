import React, {Component} from 'react';
import API from '../ApiService'
import {Link} from "react-router-dom";
import icoMoney from "./money@2x.png";
import icoHistory from "./history-line@2x.png";
import PopUp from "../popup/PopUp";
import Hint from "../hint/Hint";
import Select from "../form/Select";
import Loader from "../Loader/Loader";


export default class Payment extends Component {
    state = {
        orderLink: null,
        isSuccessOpen: false,
        loading: false,
    };

    createOrder = (e) => {
        e.preventDefault();

        this.setState({
            loading: true
        });

        API.createOrderPDF(e.target.elements['sum'].value)
            .then(url => {
                this.setState({
                    orderLink: url,
                    loading: false
                })
            });
    };

    createPeriodicOrder = (e) => {
        e.preventDefault();

        this.setState({
            day: e.target.elements['day'].value
        });

        API.createPeriodicOrder(
            e.target.elements['day'].value,
            e.target.elements['emails'].value,
            e.target.elements['sum'].value
        )
            .then(data => {
                this.openSuccess();
            })
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {
        let days = [];

        for (let i = 1; i <= 31; i++) {
            days.push({key: i, text: i.toString(), value: i});
        }

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h1>Пополнить счет</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-5">
                        <form className="box" onSubmit={this.createOrder}>
                            <div className="box__title" style={{backgroundImage: `url('${icoMoney}')`}}>
                                Разовый платеж
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-4">
                                        <label>Сумма</label>
                                        <input type="text" name="sum" pattern="\d+[.,]?\d{0,2}"
                                               placeholder="0,00"
                                               required/>
                                    </div>
                                    <div className="col-sm-8">
                                        <button className="button button_medium button_right" style={{float: 'right'}}>
                                            Сформировать счет
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-lg-7">
                        <form className="box" onSubmit={this.createPeriodicOrder}>
                            <div className="box__title" style={{backgroundImage: `url('${icoHistory}')`}}>
                                Ежемесячный платеж
                            </div>

                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-3">
                                        <label>Сумма</label>
                                        <input type="text" name="sum" min="1"
                                               placeholder="0,00"
                                               pattern="\d+[.,]?\d{0,2}" required/>
                                    </div>
                                    <div className="col-sm-3">
                                        <label style={{whiteSpace: 'nowrap'}}>Email для счетов</label>
                                        <input type="email" name="emails" required/>
                                    </div>
                                    <div className="col-sm-2">
                                        <label>
                                            День
                                            <Hint>
                                                День, в который каждый месяц на ваш Email будет приходить счет
                                            </Hint>
                                        </label>
                                        <Select
                                            options={days}
                                            defaultValue={1}
                                            inputName='day'
                                        />
                                    </div>
                                    <div className="col-sm-4">
                                        <button className="button button_medium button_right" style={{float: 'right'}}>
                                            Отправить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <Loader show={this.state.loading}/>

                {this.state.orderLink &&
                <div className="row">
                    <div className="col-sm">
                        <div className="box">
                            <iframe title="Order" src={this.state.orderLink} width="100%" className="pdf"/>
                            <a className="button button_medium" style={{maxWidth: '150px'}} target="_blank"
                               rel="noopener noreferrer"
                               href={this.state.orderLink}
                               download={this.state.orderLink}>
                                Сохранить
                            </a>
                        </div>
                    </div>
                </div>
                }

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Правило создано</div>
                    <div className="Modal__description">
                        Счет будет высылаться на почту каждый месяц {this.state.day} числа
                    </div>
                    <Link to="/" className="button" onClick={this.closeSuccess}>Ok</Link>
                </PopUp>
            </div>)
    }
};
