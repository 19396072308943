/* eslint import/no-webpack-loader-syntax: off */

import moment from "moment"
import API from "../ApiService"
import React from "react"
import styles from "./AutoinfoConstruct.module.scss"
import classnamesBind from 'classnames/bind'
import {Link, Redirect} from 'react-router-dom'
import Dropzone from 'react-dropzone'
import ArrowRight from "!svg-react-loader!../../assets/icons/arrowRight.svg"
import Play from "!svg-react-loader!../../assets/icons/play.svg"
import Check from "!svg-react-loader!../../assets/icons/Check.svg"
import Plus from "!svg-react-loader!../../assets/icons/plus.svg"
// import AudioFile from "!svg-react-loader!../../assets/icons/AudioFile.svg"
import Close from "!svg-react-loader!../../assets/icons/Close.svg"
import Modal from "react-modal"

const cx = classnamesBind.bind(styles)

class AudioManagerCl {

      audioOptionsByName = {}
      audiosByName = {}
      audioPlayingName = ""

      addAudio(data, options) {
        const audio = new Audio(data.link)
        if (options.loadeddata) audio.addEventListener('loadeddata', options.loadeddata, false)
        if (options.onended) audio.addEventListener('ended', options.onended, false)
        if (options.onpaused) audio.addEventListener('pause', options.onpaused, false)
        if (options.onplay) audio.addEventListener('play', options.onplay, false)
        this.audiosByName[data.name] = audio
        this.audioOptionsByName[data.name] = options
        return audio
      }

      removeAudio(name) {
        const options = this.audioOptionsByName[name]
        const audio = this.audiosByName[name]
        if (options.loadeddata) {
          audio.removeEventListener('loadeddata', options.loadeddata)
        }
        if (options.onended) {
          audio.removeEventListener('ended', options.onended)
        }
        if (options.onpaused) {
          audio.removeEventListener('pause', options.onpaused)
        }
        if (options.onplay) {
          audio.removeEventListener('play', options.onplay)
        }
        audio.pause()
        delete this.audiosByName[name]
      }

      addAudioList(list, options) {
        this.audiosByName = {
          ...this.audiosByName,
          ...list.reduce((acc, cur) => {
            this.addAudio(cur, options)
            return acc
          })
        }

      }

      getAudio(name) {
        return this.audiosByName[name]
      }

      playAudio(name) {
        if (this.audioPlayingName) {
          this.stopAudio(this.audioPlayingName)
        }
        const audio = this.audiosByName[name]
        if (!audio) return
        try{
          audio.play()
        } catch (e) {
          console.log(e)
        }
        this.audioPlayingName = name
      }

      pauseAudio(name) {
        const audio = this.audiosByName[name]
        if (!audio) return
        audio.pause()
        this.audioPlayingName = ""
      }

      stopAudio(name) {
        const audio = this.audiosByName[name]
        if (!audio) return
        audio.pause()
        audio.currentTime = 0.0
        this.audioPlayingName = ""
      }
}

const AudioManager = new AudioManagerCl()

class Sample extends React.Component {

  state = {
    uniqueId: Math.random(),
    isPlaying: false,
    isLoaded: false,
  }

  componentDidMount() {
    AudioManager.addAudio({
      name: this.props.name + this.state.uniqueId,
      link: this.props.link,
    }, {
      loadeddata: () => {
        this.setState({
          isLoaded: true,
        })
      },
      onplay: () => {
        this.setState({
          isPlaying: true,
        })
      },
      onended: () => {
        this.setState({
          isPlaying: false,
        })
      },
      onpaused: () => {
        this.setState({
          isPlaying: false,
        })
      }
    })
  }

  componentWillUnmount() {
    AudioManager.removeAudio(this.props.name + this.state.uniqueId)
  }

  onPlayClick = () => {
    const { isPlaying } = this.state
    if (isPlaying) {
      AudioManager.stopAudio(this.props.name + this.state.uniqueId)
    } else {
      AudioManager.playAudio(this.props.name + this.state.uniqueId)
    }
  }

  render() {
    const { name } = this.props
    const { isPlaying } = this.state
    return (
      <div onClick={ this.onPlayClick } className={ cx("sample") }>
        <div className={ cx("play") }>
          {
            isPlaying
            ? <div className={ cx("stopIcon") }/>
            : <Play/>
          }
        </div>
        <div className={ cx("name") }>
          { name }
        </div>
      </div>
    )
  }
}


class AutoinfoConstruct extends React.Component {

  state = {
    data: [],
    startDate: moment().subtract(1, 'month'),
    endDate: moment(),
    authors: [],
    fones: [],
    authorsMoreInfoOpened: {},
    selectedSpeakerId: -1,
    selectedMusicId: -1,
    needMusic: true,
    musicDuration: 5,
    musicDurationError: "",
    audioFile: null,
    audioFileLink: "",
    mainLoading: false,
    formDone: false,
    isSuccessOpen: false,
    isErrorOpen: false,
    allOpenedActive: false,
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {

    this.setState({
      mainLoading: true,
    })

    const [err, data] = await API.getAutoinfoConstructorPageData()

    this.setState({
      mainLoading: false,
    })

    if (err) {
      debugger
      return
    }

    if (!data) {
      debugger
      return
    }


    const {authors, fones} = data

    if (authors && authors.length) {
      this.setState({
        authors,
      })
    }

    if (fones && fones.length) {
      this.setState({
        fones,
      })
    }
  }

  onMoreInfoClick = (authorId) => {
    if (this.state.authorsMoreInfoOpened[authorId]) {
      this.setState({
        authorsMoreInfoOpened: {
          ...this.state.authorsMoreInfoOpened,
          [authorId]: false,
        }
      })
    } else {
      this.setState({
        authorsMoreInfoOpened: {
          ...this.state.authorsMoreInfoOpened,
          [authorId]: true,
        }
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const doc = document.documentElement;
    const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

    if (prevState.selectedSpeakerId > -1 && this.state.selectedSpeakerId < 0) {
      const cardOffsetTop = document.querySelector("#speakerId" + prevState.selectedSpeakerId).offsetTop
      window.scrollTo({
        top: cardOffsetTop - 100,
      })
    }

    if (prevState.selectedSpeakerId < 0 && this.state.selectedSpeakerId > -1) {
      if (top > document.querySelector("#speakersSec").offsetTop) {
        window.scrollTo({
          top: document.querySelector("#speakersSec").offsetTop - 100,
        })
      }
    }
  }

  onSpeakerDeselect = () => {
    // const oldSelectedSpId = this.state.selectedSpeakerId

    this.setState({
      selectedSpeakerId: -1,
    })

    // setTimeout(() => {
    //   window.scrollTo({
    //     top: document.querySelector("#speakerId" + oldSelectedSpId).offsetTop - 100,
    //   })
    // }, 50)
  }

  onSpeakerSelect = (id) => {
    if (AudioManager.audioPlayingName) {
      AudioManager.stopAudio(AudioManager.audioPlayingName)
    }
    this.setState({
      selectedSpeakerId: id,
      undoneSec: this.state.undoneSec === "speakersSec" ? "" : this.state.undoneSec,
    })

    // setTimeout(() => {
    //   const doc = document.documentElement;
    //   const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
    //   console.log(top)
    //   if (top > document.querySelector("#speakersSec").offsetTop) {
    //     window.scrollTo({
    //       top: document.querySelector("#speakersSec").offsetTop - 150,
    //     })
    //   }
    // }, 100)
  }

  onMusicSelect = (id) => {
    if (AudioManager.audioPlayingName) {
      AudioManager.stopAudio(AudioManager.audioPlayingName)
    }
    if (this.state.selectedMusicId === id) {
      this.setState({
        selectedMusicId: -1,
        audioFile: null,
      })
    } else {
      this.setState({
        selectedMusicId: id,
        audioFile: null,
        undoneSec: this.state.undoneSec === "fonesSec" ? "" : this.state.undoneSec,
      })
    }
  }

  renderSpeaker = (data) => {
    const {
      description,
      fio,
      avatar_url,
      more_info,
      // order,
      // role,
      id,
      price,
      samples,
      type,
      vocal_type,
      type_tooltip,
    } = data
    const {
      authorsMoreInfoOpened,
      selectedSpeakerId,
    } = this.state
    const isSelected = selectedSpeakerId === id
    const isOpened = authorsMoreInfoOpened[id]

    return (
      <div key={ id } id={ "speakerId" + id } className={ cx("item", {"deactivated": selectedSpeakerId > -1 && !isSelected}) }>
        <div className={ cx("body") }>
          <div className={ cx("typeWr") }>
            <div className={ cx("type") }>
              <div className={ cx("tooltip-wr", "speakerType") }>
                <div className={ cx("") }>
                    { type }
                </div>
                <span className={ cx("hint") }>
                  { type_tooltip }
                </span>
              </div>
            </div>
          </div>
          <div className={ cx("main") }>
            <div className={ cx("dataWr") }>
              {
                avatar_url
                && <div className={ cx("avatar") } style={{backgroundImage: `url('${avatar_url}')`}}/>
              }
              <div className={ cx("info") }>
                <div className={ cx("name") }>
                  { fio }
                </div>
                <div className={ cx("vocalType") }>
                  { vocal_type }
                </div>
                <div className={ cx("samples") }>
                  {
                    samples.map(d => {
                      return <div key={d.link} style={{marginBottom: 10}}>
                        <Sample name={d.name} link={d.link}/>
                      </div>
                    })
                  }
                </div>
              </div>
            </div>
            <div className={ cx("dscWr") }>
              <div className={ cx("dsc") }>
                { description }
              </div>
              <div className={ cx("moreInfoShort", {"hidden": isOpened}) }>
                {
                  more_info[0] &&
                  more_info[0].content.map(c => {
                    return <img key={c.name} alt={c.name} src={c.image} className={ cx("item") }/>
                  })
                }
              </div>
              {
                more_info
                && more_info.length > 0
                && more_info[0].content
                && more_info[0].content.length > 0
                && <div
                  onClick={ () => this.onMoreInfoClick(id) }
                  className={ cx("moreInfoExpander", {"opened": isOpened}) }
                >
                  <div className={ cx("text") }>
                    Больше информации
                  </div>
                  <ArrowRight className={cx("icon")}/>
                </div>
              }
              <div className={ cx("moreInfoSec", {"opened": isOpened}) }>
                {
                  more_info && more_info.map(mi => {
                    return (
                      <div key={mi.text} className={ cx("item") }>
                        <div className={ cx("text") }>
                          { mi.text }
                        </div>
                        <div className={ cx("content") }>
                          {
                            mi.content && mi.content.map(c => {
                              return <img key={ c.image } src={ c.image } alt={ c.name } className={ cx("item") }/>
                            })
                          }
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className={ cx("selectWr") }>
              <div className={ cx("price") }>
                { price } Р
              </div>
              {
                isSelected
                ? <button onClick={ () => this.onSpeakerDeselect(id) } className="button button-blue"> <Check/> Выбран</button>
                  : <button onClick={ () => this.onSpeakerSelect(id) } className="button button-transparent button-rounded" >Выбрать</button>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }

  handleChangeNeedMusic = (changeEvent) => {
      this.setState({
        needMusic: changeEvent.target.value === "true",
      })
  }

  handleMusicDurationInputChange = (event) => {
    const value = event.target.value
    let errased = false
    if (!/сек/.test(value)) {
      errased = true
    }
    let valueCleared = value.replace(/\D*/gi, '').replace(/^0/, "")
    if (errased) {
      valueCleared = valueCleared.slice(0, -1)
    }
    console.log(valueCleared)
    if (valueCleared < 91) {
      this.setState({
        musicDuration: valueCleared || 0,
        musicDurationError: "",
      })
    } else {
      this.setState({
        musicDurationError: "Должно быть мене 91 секунды",
      })
    }
  }

  onTextareaChange = (event) => {
      this.setState({
        text: event.target.value,
        undoneSec: this.state.undoneSec === "textSec" ? "" : this.state.undoneSec,
      })
  }

  onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      alert("Вы ставили или не аудио файл, или файл больше 2 мб")
      return
    }
    if (acceptedFiles) {
      this.setState({
        audioFile: acceptedFiles[0],
        audioFileLink: URL.createObjectURL(acceptedFiles[0]),
        selectedMusicId: 9999,
        undoneSec: this.state.undoneSec === "fonesSec" ? "" : this.state.undoneSec,
      })
    }
  }

  audioFileDeselect = () => {
    this.setState({
      audioFile: null,
      selectedMusicId: 0,
    })
  }

  formIsInvalid = () => {
    const {
      selectedSpeakerId,
      needMusic,
      selectedMusicId,
      text,
    } = this.state

    const speakerDone = selectedSpeakerId > -1;

    if (!speakerDone) {
      window.scrollTo({
        top: document.querySelector("#speakersSec").offsetTop - 30,
        behavior: "smooth",
      })
      this.setState({
        undoneSec: "speakersSec",
      })
      return true
    }

    const musicDone = !needMusic || selectedMusicId > -1;

    if (!musicDone) {
      window.scrollTo({
        top: document.querySelector("#fonesSec").offsetTop - 30,
        behavior: "smooth",
      })
      this.setState({
        undoneSec: "fonesSec",
      })
      return true
    }

    const textDone = text;

    if (!textDone) {
      window.scrollTo({
        top: document.querySelector("#textSec").offsetTop - 30,
        behavior: "smooth",
      })
      this.setState({
        undoneSec: "textSec",
      })
      return true
    }

    return false
  }

  sendForm = async () => {

    if (this.formIsInvalid()) {
      return;
    }

    const { needMusic } = this.state

    this.setState({
      mainLoading: true,
    })

    const selectedMusic = this.state.fones.filter(auth => auth.id === this.state.selectedMusicId)[0]
    const selectedSpeaker = this.state.authors.filter(auth => auth.id === this.state.selectedSpeakerId)[0]
    const form = {
      dictors: (selectedSpeaker && selectedSpeaker.fio) || "",
      fonMusic: (!needMusic && "нет") || (selectedMusic && selectedMusic.name) || "",
      fonMusicDuration: (!needMusic && "нет") || this.state.musicDuration,
      text: this.state.text,
      files: (!needMusic && []) || [this.state.audioFile],
    }

    const [err, data] =  await API.sendAutoinfoConstructorForm(form)

    this.setState({
      mainLoading: false,
    })

    if (err) {
      debugger
      alert("Что-то пошло не так, попробуйте чуть позже")
      this.setState({
        isErrorOpen: true,
      })
      return
    }

    if (data) {
      // alert("Поздравляем, ваша заявка принята")
      this.setState({
        isSuccessOpen: true,
      })
    }

  }


  closeSuccessModal = () => {
      this.setState({
        formDone: true,
      })
  }

  onChangeAllOpenedActive = (event) => {
    this.setState({
      allOpenedActive: event.target.checked,
      authorsMoreInfoOpened: event.target.checked ? this.state.authors.reduce((acc, cur) => {
        acc[cur.id] = true
        return acc
      }, {}) : {},
    })
  }

  render() {
    const {
      selectedSpeakerId,
      fones,
      selectedMusicId,
      needMusic,
      musicDuration,
      musicDurationError,
      text,
      audioFile,
      audioFileLink,
      mainLoading,
      formDone,
      isSuccessOpen,
      isErrorOpen,
      allOpenedActive,
      undoneSec,
    } = this.state

    if (formDone) {
      return <Redirect push to="/" />
    }

    const speakerDone = selectedSpeakerId > -1;
    const musicDone = !needMusic || (selectedMusicId > -1 && speakerDone);
    const textDone = text;
    // const sendDone = speakerDone && musicDone && textDone;

    return (
      <div className={ "call-detail " + cx("wr", {"loading": mainLoading}) }>
        <h1>Конструктор автоинформатора</h1>

        <div className={ cx("breadcrumbs") }>
        	<div className={ cx("item", {"done": speakerDone, "current": selectedSpeakerId === -1}) }>
            <div className={ cx("num") }>
            	1
            </div>
            <div className={ cx("name") }>
            	Диктор
            </div>
        	</div>
          <div className={ cx("arrow") }>
            <ArrowRight/>
          </div>
          <div className={ cx("item", {"done": musicDone, "current": selectedSpeakerId === -1 && !selectedMusicId}) }>
            <div className={ cx("num") }>
              2
            </div>
            <div className={ cx("name") }>
              Фоновая<br/>музыка
            </div>
          </div>
          <div className={ cx("arrow") }>
            <ArrowRight/>
          </div>
          <div className={ cx("item", {"done": textDone, "current": !needMusic || (selectedMusicId >= 0 && !text)}) }>
            <div className={ cx("num") }>
              3
            </div>
            <div className={ cx("name") }>
              Текст
            </div>
          </div>
          <div className={ cx("arrow", "lastSteps") }>
            <ArrowRight/>
          </div>
          <div className={ cx("item", "lastSteps", {"current": speakerDone && musicDone && textDone}) }>
            <div className={ cx("num") }>
              4
            </div>
            <div className={ cx("name") }>
              Готово!
            </div>
          </div>
        </div>
        <div className={ cx("speakers") } id={ "speakersSec" }>
        	<div className={ cx("ctrl") }>
        		<div className={ cx("blockTitle", {"undone": undoneSec === "speakersSec"}) }>
              Выберите голос
        		</div>
            <div className={ cx("moreWr") }>
              <label className={ cx("form-switch") }>
                <span className={ cx("text") }>
                	Подробнее обо всех
                </span>
                <input checked={ allOpenedActive } onChange={ this.onChangeAllOpenedActive } type="checkbox"/>
                  <i></i>
                </label>
            </div>
        	</div>
          <div className={ cx("list") }>
            {
              this.state.authors.map(this.renderSpeaker)
            }
          </div>
        </div>
        <div className={ cx("music") } id={ "fonesSec" }>
        	<div className={ cx("ctrl") }>
        		<div className={ cx("blockTitle", {"undone": undoneSec === "fonesSec"}) }>
              Вам нужна фоновая музыка?
        		</div>
            <div className={"radiobutton__row " + cx("radioRow")}>
              <label className={ cx("item") }>
                <input
                  className="radiobutton"
                  type="radio"
                  value={ true }
                  checked={ needMusic }
                  onChange={this.handleChangeNeedMusic}
                />
                <span className="radiobutton-custom"/>
                <span className="label label-checkradio">Да</span>
              </label>
              <label className={ cx("item") }>
                <input
                  className="radiobutton"
                  type="radio"
                  value={ false }
                  checked={ !needMusic }
                  onChange={this.handleChangeNeedMusic}
                />
                <span className="radiobutton-custom"/>
                <span className="label label-checkradio">Нет</span>
              </label>
            </div>
        	</div>
          {
            needMusic
            && <div className={ cx("panel") }>
              <div className={ cx("list") }>
                {
                  fones.map((d, i) => {
                    if (d.id === undefined) {
                      return <div
                          key={i}
                          className={ cx("item", "empty") }
                        >
                      </div>
                    }

                    return (
                      <div
                        key={d.id}
                        className={ cx("item", {"selected": d.id === selectedMusicId}) }
                      >
                        <div className={ cx("choose") }>
                          <Check/>
                        </div>
                        <div onClick={ () => this.onMusicSelect(d.id) } className={ cx("bg") }/>
                        <div className={ cx("sampleSec") }>
                          <Sample key={d.link} name={ d.name.replace(".mp3", "") } link={d.link}/>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
              <div className={ cx("additional") }>
              	<div className={ cx("sec", "load") }>
                  {
                    audioFile
                    ? <div className={ cx("loadWr") }>
                        <div className={ cx("audioFile") }>
                          {/*<div className={ cx("icon") }>
                            <AudioFile/>
                          </div>*/}
                          <div className={ cx("desc") }>
                            <div className={ cx("name") }>
                              {/*{ audioFile.name }*/}
                              <Sample name={ audioFile.name } link={ audioFileLink }/>
                            </div>
                            <div className={ cx("size") }>
                              { audioFile.size / 1000 } kb
                            </div>
                          </div>
                          <div onClick={ this.audioFileDeselect } className={ cx("close") }>
                            <Close/>
                          </div>
                        </div>
                      </div>
                      : <Dropzone
                        maxSize={ 2000000 }
                        accept={ "audio/mp3, audio/mpeg" }
                        onDrop={this.onDrop}
                      >
                        {({getRootProps, getInputProps, isDragActive}) => {
                          return (
                            <div {...getRootProps()} className={ cx("loadWr") }>
                              {
                                isDragActive
                                  ? <div className={ cx("text") }>
                                    Прикрепить сюда
                                  </div>
                                  : <div className={ cx("text") }>
                                    Прикрепить свою музыку
                                    <span className={ cx("tooltip-wr") }>
                                      <span className={ cx("tooltip") }>
                                        <svg width="17" height="17" viewBox="0 0 17 17" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                          <path
                                            d="M15.5 8C15.5 12.1421 12.1421 15.5 8 15.5C3.85786 15.5 0.5 12.1421 0.5 8C0.5 3.85786 3.85786 0.5 8 0.5C12.1421 0.5 15.5 3.85786 15.5 8Z"
                                            transform="translate(0.335938 0.522461)"
                                            fill="white" stroke="#4A4A4A" strokeLinejoin="round"/>
                                          <path
                                            d="M1.92253 8.59766C1.93034 8.13281 1.98307 7.76562 2.08073 7.49609C2.17839 7.22656 2.3776 6.92773 2.67839 6.59961L3.44596 5.80859C3.77409 5.4375 3.93815 5.03906 3.93815 4.61328C3.93815 4.20312 3.83073 3.88281 3.61589 3.65234C3.40104 3.41797 3.08854 3.30078 2.67839 3.30078C2.27995 3.30078 1.95964 3.40625 1.71745 3.61719C1.47526 3.82812 1.35417 4.11133 1.35417 4.4668H0.270182C0.277995 3.83398 0.502604 3.32422 0.94401 2.9375C1.38932 2.54687 1.96745 2.35156 2.67839 2.35156C3.41667 2.35156 3.99089 2.55078 4.40104 2.94922C4.8151 3.34375 5.02214 3.88672 5.02214 4.57812C5.02214 5.26172 4.70573 5.93555 4.07292 6.59961L3.43424 7.23242C3.14909 7.54883 3.00651 8.00391 3.00651 8.59766H1.92253ZM1.87565 10.4551C1.87565 10.2793 1.92839 10.1328 2.03385 10.0156C2.14323 9.89453 2.30339 9.83398 2.51432 9.83398C2.72526 9.83398 2.88542 9.89453 2.99479 10.0156C3.10417 10.1328 3.15885 10.2793 3.15885 10.4551C3.15885 10.6309 3.10417 10.7773 2.99479 10.8945C2.88542 11.0078 2.72526 11.0645 2.51432 11.0645C2.30339 11.0645 2.14323 11.0078 2.03385 10.8945C1.92839 10.7773 1.87565 10.6309 1.87565 10.4551Z"
                                            transform="translate(5.87695 1.52246)" fill="#4A4A4A"/>
                                        </svg>
                                      </span>
                                      <span className={ cx("hint") }>
                                        Файл не более 2 мб
                                      </span>
                                    </span>
                                  </div>
                              }
                              <input {...getInputProps()} />
                              <div className={ cx("iconWr") }>
                                <Plus/>
                              </div>
                            </div>
                          )
                        }}
                      </Dropzone>
                  }
              	</div>
                <div className={ cx("sec", "duration") }>
                  <div className={ cx("content") }>
                    <div className={ cx("text") }>
                      Длительность музыки после текста
                    </div>
                    <input
                      value={ musicDuration + " сек" }
                      onChange={ this.handleMusicDurationInputChange }
                      type="text"
                      className={ cx("input", {"error": musicDurationError}) }
                    />
                    {
                      musicDurationError
                      &&  <div className={ cx("musicDurationError") }>
                        { musicDurationError }
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        <div className={ cx("textPanel") } id={ "textSec" }>
          <div className={ cx("ctrl") }>
            <div className={ cx("blockTitle", {"undone": undoneSec === "textSec"}) }>
              Текст
            </div>
          </div>
          <div className={ cx("panel") }>
          	<div className={ cx("name") }>
          		Введите текст
          	</div>
            <textarea
              className={ cx("textarea") }
              placeholder={"Текст, который будет читать диктор"}
              cols="30" rows="3"
              value={ text }
              onChange={ this.onTextareaChange }
            >

            </textarea>
          </div>
        </div>
        <div className={ cx("sendButton") }>
          <button onClick={ this.sendForm } className="button button-blue" style={{marginBottom: '50px'}}>Отправить</button>
          {/*
            !sendDone && <ul dir="rtl" className={ cx("list") }>
              {
                !speakerDone && <li>Выберите диктора</li>
              }
              {
                !musicDone && <li>Выберите трек</li>
              }
              {
                !textDone && <li>Впишите текст</li>
              }
            </ul>
          */}
        </div>
        <Modal className="Modal" isOpen={isSuccessOpen}>
          <div className="close-button" onClick={this.closeSuccessModal}>
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L28.2983 28.2983" transform="translate(1.2041 1.79102)" stroke="#979797"
                    strokeWidth="2"/>
              <path d="M28.2983 0.5L0.5 29.5" transform="translate(1.2041 0.791016)" stroke="#979797"
                    strokeWidth="2"/>
            </svg>
          </div>
          <div className="status__card-icon status-ok"/>
          <div className="status__card-title">Ваш запрос принят</div>
          <Link to="/" className="button button-blue" onClick={this.closeSuccessModal}>Вернуться на
            главную</Link>
        </Modal>
        <Modal className="Modal" isOpen={isErrorOpen}>
          <div className="close-button" onClick={this.closeSuccessModal}>
            <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0 0L28.2983 28.2983" transform="translate(1.2041 1.79102)" stroke="#979797"
                    strokeWidth="2"/>
              <path d="M28.2983 0.5L0.5 29.5" transform="translate(1.2041 0.791016)" stroke="#979797"
                    strokeWidth="2"/>
            </svg>
          </div>

          <div className="status__card-icon status-error"/>
          <div className="status__card-title">Ошибка</div>
          <Link to="/" className="button button-blue">Попробуйте чуть позже</Link>
        </Modal>
      </div>
    )
  }
}

export default AutoinfoConstruct;