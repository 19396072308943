import React from 'react';
import API from '../ApiService';
import './application-templates.css'
import moment from "moment";
import DatePicker from "../calendar/DatePicker";
import mailTemplate from "../mail/template";
import PopUp from "../popup/PopUp";
import AdditionalPhone from "../form/AdditionalPhone";


export default class Moving extends React.Component {

    state = {
        me: {},
        isSuccessOpen: false,
        date: moment().add(1, 'day').toDate(),
    };

    componentWillMount() {
        API.me()
            .then(data => {
                this.setState({me: data});
            });
    }

    sendMessage = (e) => {
        e.preventDefault();

        let message = mailTemplate(<dl>
            <dt key='dt-text'/>
            <dd key='dd-text'>Пользователь подал заявку на переезд.</dd>

            <dt key='dt-address'>Дата переезда:</dt>
            <dd key='dd-address'>{e.target.elements['date'].value}</dd>

            <dt key='dt-address'>Адрес:</dt>
            <dd key='dd-address'>{e.target.elements['address'].value}</dd>

            <dt key='dt-phone'>Номер телефона:</dt>
            <dd key='dd-phone'>{e.target.elements['phone'].value}</dd>

            <dt key='dt-additionalPhone'>Другой номер телефона:</dt>
            <dd key='dd-additionalPhone'>{e.target.elements['additionalPhone'].value}</dd>

            <dt key='dt-name'>Имя:</dt>
            <dd key='dd-name'>{e.target.elements['name'].value}</dd>
        </dl>);

        API.sendMail(message, e.target.id).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
        });
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h1>Переезд</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <form className="box" id="moving" onSubmit={this.sendMessage}>
                            <div className="container">
                                <div className="row align-items-end">
                                    <div className="col-lg">
                                        <div className="container">
                                            <div className="row">
                                                <div className="col">
                                                    <h2>Укажите дату переезда и новый адрес</h2>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md">
                                                    <label>Контактное лицо</label>
                                                    <input type="text" required name="name"
                                                           defaultValue={this.state.me.clientContact}/>
                                                </div>
                                                <div className="col-md">
                                                    <label>Дата переезда</label>
                                                    <DatePicker
                                                        name="date"
                                                        required
                                                        readOnly
                                                        minDate={this.state.date}
                                                        date={this.state.date}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <label>Новый адрес</label>
                                                    <input type="text" required name="address"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <AdditionalPhone clientPhone={this.state.me.clientPhone}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваша заявка на переезд принята</div>
                    <div className="button button_big" onClick={this.closeSuccess}>Ok</div>
                </PopUp>
            </div>
        )
    }
};