import {get} from 'lodash';
import moment from 'moment';
import API from "../ApiService";
import React from 'react';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import 'react-datepicker/dist/react-datepicker.css';
import 'core-js/es6/number';
import 'core-js/es6/array';
import DateRangePicker from "../calendar/DateRangePicker";
import Download from "../form/Download";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner'
import CallDetailRequest from "./CallDetailRequest";
import Select from "../form/Select";

export default class CallDetail extends React.Component {

    state = {
        data: [],
        startDate: moment().subtract(2, 'weeks').toDate(),
        endDate: moment().toDate(),
        loading: true,
        manyData: false,
        clientsEmails: null,
    };

    componentDidMount() {
        this.getData();
    }

    _getPeriod = () => {
        return {
            fromDate: moment(this.state.startDate).format('DD-MM-YYYY'),
            toDate: moment(this.state.endDate).format('DD-MM-YYYY'),
        };
    };

    getData = () => {
        this.setState({
            loading: true,
        });

        return API.stats(this._getPeriod()).then(response => {
            let manyData = false;
            let data = response['telephone'];

            data.forEach((item, k) => {
                const date = item['callDate'].split(' ');

                item['callDate'] = {
                    'day': date[0],
                    'time': date[1],
                };
            });

            const nCalls = get(response, 'nCalls', data.length);

            if (nCalls > 500) {
                data = [];
                manyData = true;
            }

            this.setState({
                data: data,
                phones: data.length ? ['', ...get(response, 'phone_numbers', [])].map((v, i) => ({
                    key: i,
                    text: v,
                    value: v
                })) : null,
                manyData: manyData,
                loading: false,
                clientsEmails: response['clientsEmails'],
            });
        });
    };

    handleGetFile = (e) => {
        API.statsFile(this._getPeriod(), e.target.name).then(url => {
            window.open(url);
        });
    };

    showStat = (e) => {
        e.preventDefault();
        this.getData();
    };

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({
            startDate: startDate,
            endDate: endDate,
        });
    };

    render() {
        const {loading, manyData, startDate, endDate, clientsEmails, phones, selectedPhone} = this.state;
        let {data} = this.state;
        const spinner = <div className="loader">
            <Loader type="TailSpin" color="#174ebf" height={50} width={50}/>
            <div className="title">Загрузка данных</div>
        </div>
        const noData = <div className="loader">
            <div className="no-data"/>
            <div className="title">За выбранный период звонков нет</div>
        </div>
        const mail = <CallDetailRequest startDate={startDate} endDate={endDate} clientsEmails={clientsEmails}/>
        const message = loading ? spinner : manyData ? mail : noData;

        if (selectedPhone) {
            data = data.filter(x => x['callerPhone'] === selectedPhone || x['callingPhone'] === selectedPhone)
        }

        return (
            <div className="container call-detail">
                <div className="row">
                    <div className="col-sm-7">
                        <h1>Детализация звонков</h1>
                    </div>
                    <div className="col-sm-5">
                        {/*<a className="button button-white button-promo" href="/">Управление "ПРОМО-офис"</a>*/}
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="box">

                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-md-4">
                                        <label>Период</label>

                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            endDate={this.state.endDate}
                                            onChange={this.handleDateRangeChange}
                                            style={{float: 'left', marginRight: '20px', maxWidth: '300px'}}
                                        />
                                    </div>
                                    {phones &&
                                    <div className="col-xl-2 col-md-3">
                                        <label>Номер</label>
                                        <Select
                                            options={phones}
                                            inputName='agreement'
                                            onChange={(e, {value}) => {
                                                this.setState({selectedPhone: value})
                                            }}
                                        />
                                    </div>
                                    }
                                    <div className="col-md-4">
                                        <button className="button" onClick={this.showStat} style={{marginTop: '22px'}}>
                                            Показать
                                        </button>
                                    </div>
                                    <div className="col-md">
                                        <Download
                                            getFile={this.handleGetFile}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ReactTable
                                data={data}
                                columns={[
                                    {
                                        Header: 'Дата и время',
                                        accessor: 'callDate',
                                        minWidth: 130,
                                        Cell: item => (
                                            <div style={{whiteSpace: 'nowrap', width: '200px'}}>
                                                {item.value['day']}&nbsp;&nbsp;{item.value['time']}
                                            </div>
                                        )
                                    },
                                    {
                                        Header: 'Вызывающий номер',
                                        accessor: 'callerPhone',
                                        sortable: false,
                                        minWidth: 120,
                                    },
                                    {
                                        Header: 'Вызываемый номер',
                                        accessor: 'callingPhone',
                                        sortable: false,
                                        minWidth: 130,
                                    },
                                    {
                                        Header: 'Длительность',
                                        accessor: 'callDuration',
                                        sortable: false,
                                    },
                                    {
                                        Header: 'Стоимость, ₽',
                                        accessor: 'callPrice',
                                        sortable: true,
                                    },
                                ]}

                                minRows={0}
                                defaultPageSize={30}
                                showPageJump={true}
                                showPageSizeOptions={false}
                                pageText=''
                                ofText='из'
                                previousText='←'
                                nextText='→'
                                noDataText={message}
                                loadingText={message}

                                sortable={true}
                                multiSort={false}
                                defaultSorted={[
                                    {
                                        id: 'callDate',
                                        desc: true
                                    }
                                ]}
                                resizable={false}
                                filterable={false}
                                className="call-detail__table"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};