import React from 'react';
import ReactTable from 'react-table'
import API from "../ApiService";
import moment from "moment";
import CompareAct from "./CompareAct";
import CompareActOriginal from "./CompareActOriginal";
import 'core-js/es6/number';
import 'core-js/es6/array';
import values from 'object.values';
import DateRangePicker from "../calendar/DateRangePicker";
import 'react-table/react-table.css'
import Select from "../form/Select";
import strToTime from '../strToTime'


export default class FinDoc extends React.Component {

    emptyAgreement = {key: 0, text: '', value: ''};

    state = {
        data: [],
        agreements: [this.emptyAgreement],
        filter: {},
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: moment().toDate(),
        isSuccessOpen: false,
        noDataText: 'Нет данных для показа',
    };

    componentDidMount() {
        this.showData();
    }

    showData() {
        this.setState({
            noDataText: 'Загрузка…',
        });

        const period = {
            startDate: this.state.startDate,
            stopDate: this.state.endDate,
        };

        API.meFinDocumentsGet(period).then(data => {
            if (!Object.values) {
                values.shim();
            }

            if (!data) return;

            data = Object.values(data);

            data.forEach((item) => {
                item['bill'] = {
                    num: item.num,
                    type: item.type,
                    url: item.url,
                };
            });

            let availableAgreements = [];
            data.forEach(item => {
                if (!availableAgreements.includes(item['agreementName'])) {
                    availableAgreements.push(item['agreementName']);
                }
            });

            if (this.state.selectedAgreement) {
                data = data.filter(item => {
                    return item['agreementName'] === this.state.selectedAgreement;
                });
            }

            if (this.state.sumFrom) {
                data = data.filter(item => {
                    return item.summ > this.state.sumFrom;
                });
            }

            if (this.state.sumTo) {
                data = data.filter(item => {
                    return item.summ < this.state.sumTo;
                });
            }

            this.setState({
                data: data,
                agreements: [this.emptyAgreement].concat(
                    availableAgreements.map((value, i) => {
                        return {key: i + 1, text: value.toString(), value: value};
                    })),
            });
        });
    }

    submitForm = (e) => {
        e.preventDefault();
        this.showData(e.target.id);
    };

    handleDateRangeChange = (start, end) => {
        this.setState({
            startDate: start,
            endDate: end,
        })
    };

    render() {
        return (
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-sm-8">
                        <h1>Финансовые документы</h1>
                    </div>
                    <div className="col-sm-4">
                        <CompareActOriginal/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl">
                        <div className="box">
                            <form onSubmit={this.submitForm} id="filterFinDoc">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <label>Период</label>
                                            <DateRangePicker
                                                startDate={this.state.startDate}
                                                onChange={this.handleDateRangeChange}
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <label>Договор</label>
                                            <Select
                                                options={this.state.agreements}
                                                defaultValue={0}
                                                inputName='agreement'
                                                onChange={(e, {value}) => {
                                                    this.setState({selectedAgreement: value})
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-1">
                                            <label>От</label>
                                            <input type="text" name="sumFrom" min="1"
                                                   pattern="\d+[.,]?\d{0,2}"
                                                   onChange={e => this.setState({sumFrom: e.target.value})}
                                            />
                                        </div>
                                        <div className="col-lg-1">
                                            <label>До</label>
                                            <input type="text" name="sumTo" min="1"
                                                   pattern="\d+[.,]?\d{0,2}"
                                                   onChange={e => this.setState({sumTo: e.target.value})}
                                            />
                                        </div>
                                        <div className="col-lg">
                                            <button className="button button_medium button_right"
                                                    onClick={this.showStat}>
                                                Показать
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                            <ReactTable
                                data={this.state.data}
                                columns={[
                                    {
                                        Header: 'Счет',
                                        accessor: 'num',
                                        sortable: false,
                                    },
                                    {
                                        Header: 'Документ',
                                        accessor: 'bill',
                                        sortable: false,
                                        Cell: item => (
                                            <a href={item.value.url} target="_blank" rel="noopener noreferrer">
                                                {item.value.type}
                                            </a>
                                        )
                                    },
                                    {
                                        Header: 'Договор',
                                        accessor: 'agreementName',
                                        sortable: false,
                                    },
                                    {
                                        Header: 'Дата',
                                        accessor: 'date',
                                        defaultSortDesc: true,
                                        sortMethod: (a, b) => {
                                            a = strToTime(a);
                                            b = strToTime(b);

                                            if (a === b) {
                                                return 0;
                                            }
                                            return a > b ? 1 : -1;
                                        }
                                    },
                                    {
                                        Header: 'Сумма, ₽',
                                        accessor: 'summ',
                                        sortable: true,
                                    },
                                ]}

                                minRows={0}
                                defaultPageSize={30}
                                showPageJump={true}
                                showPageSizeOptions={false}
                                pageText=''
                                ofText='из'
                                previousText='←'
                                nextText='→'
                                noDataText={this.state.noDataText}
                                loadingText='Загрузка…'

                                sortable={true}
                                multiSort={false}
                                defaultSorted={[
                                    {
                                        id: 'date',
                                        desc: true
                                    }
                                ]}
                                filterable={false}
                                resizable={false}
                            />
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-xl">
                        <CompareAct/>
                    </div>
                </div>
            </div>
        )
    }
};