import React, {Component} from 'react';
import API from "../ApiService";
import mailTemplate from "../mail/template";
import PopUp from "../popup/PopUp";
import './support.css'
import TextareaAutosize from 'react-textarea-autosize';


export default class Support extends Component {
    state = {
        isFormOpen: false,
        isSuccessOpen: false,
        phone: '',
        additionalPhone: '',
        address: '',
        additionalAddress: '',
    };

    componentDidMount() {
        API.requisites().then(data => {
            this.setState({
                address: data.sendAddress || data.juridicalAddress
            })
        });

        API.me().then(data => {
            this.setState({
                phone: data.clientPhone.substr(0, 16),
                name: data.clientContact
            })
        })
    }

    openForm = () => {
        this.setState({isFormOpen: true});
    };

    closeForm = () => {
        this.setState({isFormOpen: false});
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    sendMessage = (e) => {
        e.preventDefault();

        this.closeForm();

        let message = mailTemplate(<dl>
            <dt key='dt-text'>Текст:</dt>
            <dd key='dd-text'>{e.target.elements['message'].value}</dd>

            <dt key='dt-address'>Адрес:</dt>
            <dd key='dd-address'>{e.target.elements['address'].value}</dd>

            <dt key='dt-additionalAddress'>Другой адрес:</dt>
            <dd key='dd-additionalAddress'>{e.target.elements['additionalAddress'].value}</dd>

            <dt key='dt-phone'>Номер телефона:</dt>
            <dd key='dd-phone'>{e.target.elements['phone'].value}</dd>

            <dt key='dt-additionalPhone'>Другой номер телефона:</dt>
            <dd key='dd-additionalPhone'>{e.target.elements['additionalPhone'].value}</dd>

            <dt key='dt-name'>Имя:</dt>
            <dd key='dd-name'>{e.target.elements['name'].value}</dd>
        </dl>);

        API.sendMail(message, e.target.id).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
            this.openErrorModal();
        });
    };

    render() {
        return (
            <div>
                <button className="support-click" onClick={this.openForm}/>

                <PopUp isOpen={this.state.isFormOpen} close={this.closeForm}
                       title="Перед отправкой заявки убедитесь, что:">
                    <form id="support" onSubmit={this.sendMessage}>
                        <div className="container">
                            <div className="row">
                                <div className="col-sm">
                                    <div className="Modal__title">
                                        Перед отправкой заявки убедитесь, что:
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label className="checkbox">
                                        <input type="checkbox" required/> Оборудование подключено к электропитанию
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                                <div className="col-sm">
                                    <label className="checkbox">
                                        <input type="checkbox" required/> Перезагружено, но проблема не исчезла
                                        <span className="checkmark"/>
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label>Ваше имя</label>
                                    <input type="text" name="name" defaultValue={this.state.name} required/>
                                </div>
                                <div className="col-sm">
                                    <label>Адрес</label>
                                    <input type="text" name="address" defaultValue={this.state.address} required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Ввести адрес вручную</label>
                                    <input type="text" name="additionalAddress"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <label>Опишите проблему</label>
                                    <TextareaAutosize name="message" required/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label style={{color: '#000'}}>Наш менеджер перезвонит вам по номеру:</label>
                                    <input name="phone" value={this.state.phone} type="hidden"/>
                                    <div className="phone">
                                        {this.state.phone}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <label>Номер для оперативной связи</label>
                                    <input type="tel" name="additionalPhone"
                                           pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                                           placeholder="+7 (999) 123-45-67"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <button type="submit" className="button button_big button_center">
                                        Отправить заявку
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PopUp>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваша заявка принята</div>
                    <div className="Modal__description">Мы свяжемся с вами в ближайшее время</div>
                    <div className="button button_big" onClick={this.closeSuccess}>Ok</div>
                </PopUp>
            </div>
        )
    }
};