import React, {Component} from 'react';
import Modal from 'react-modal';
import './popup.css'

class PopUp extends Component {

    state = {
        isOpen: false,
    };

    constructor(props) {
        super(props);

        if (props.open) {
            this.open = props.open.bind(this);
        } else {
            this.open = this.open.bind(this);
        }

        if (props.close) {
            this.close = props.close.bind(this);
        } else {
            this.close = this.close.bind(this);
        }
    }

    open() {
        this.setState({isOpen: true});
    }

    close() {
        this.setState({isOpen: false});
    }

    render() {
        return (
            <Modal className={this.props.className || 'Modal'}
                   overlayClassName="Overlay"
                   isOpen={this.props.isOpen}
                   shouldCloseOnOverlayClick={false}
            >

                <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" className="close"
                     onClick={this.close}>
                    <path
                        d="M16.224,15.221l5.987-5.987a.722.722,0,0,0-1.018-1.018L15.206,14.2,9.218,8.208A.725.725,0,0,0,8.193,9.234l5.994,5.987L8.193,21.208a.722.722,0,1,0,1.018,1.018l5.994-5.987,5.987,5.987a.722.722,0,0,0,1.018-1.018Z"
                        transform="translate(-2.206 -2.221)"/>
                    <rect width="26" height="26" fill="rgba(0,0,0,0)"/>
                </svg>

                {this.props.children}

            </Modal>
        )
    }
}


export default PopUp;