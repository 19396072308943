import React from 'react';
import API from '../ApiService';
import mailTemplate from "../mail/template";
import UploadFile from "../upload-file/UploadFile";
import ico from "./align-left-text@2x.png";
import 'bootstrap/dist/js/bootstrap'
import './requisites.css'
import PopUp from "../popup/PopUp";


export default class Requisites extends React.Component {
    state = {
        requisites: {},
        isFormOpen: false,
        isSuccessOpen: false,
        file: null
    };

    componentDidMount() {
        API.requisites().then(data => {
            this.setState({
                requisites: data,
            });
        });

        API.me().then(data => {
            this.setState({
                phone: data.clientPhone.substr(0, 16),
                name: data.clientContact
            })
        });
    }

    openForm = () => {
        this.setState({
            isFormOpen: true
        });
    };

    closeForm = () => {
        this.setState({
            isFormOpen: false
        });
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    handleChangeFile = (value) => {
        this.setState({file: value})
    };

    handleSubmit = (e) => {
        e.preventDefault();

        const requisites = [];

        this.closeForm();

        for (let key in e.target.elements) {
            if (e.target.elements.hasOwnProperty(key) && e.target.elements[key].name !== 'file') {
                const placeholder = e.target.elements[key].placeholder === '+7 (999) 123-45-67' ? 'Телефон' : e.target.elements[key].placeholder;
                requisites.push(<dt key={`x-${e.target.elements[key].name}`}>{placeholder}</dt>);
                requisites.push(<dd key={`y-${e.target.elements[key].name}`}>{e.target.elements[key].value}</dd>);
            }
        }

        API.sendMail(
            mailTemplate(<dl>{requisites}</dl>),
            e.target.id,
            this.state.file
        ).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
        });
    };

    render() {

        const requisites = this.state.requisites;
        const collapse = this.props.collapse === false ? this.props.collapse : true;

        return (
            <div className="box requisites">
                <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                    Реквизиты

                    {collapse && <div className="collapse-trigger collapsed"
                         data-toggle="collapse"
                         data-target="#requisites"
                         aria-expanded="false"
                         aria-controls="requisites"/>}
                </div>

                <div className={'container' + (collapse ? ' collapse' : '')} id="requisites">
                    <div className="row">
                        <div className="col-sm">
                            <label>Полное наименование</label>
                            <div className="value">{requisites.fullName}</div>

                            <label>Генеральный директор</label>
                            <div className="value">{requisites.chiefFIO}</div>

                            <label>Юридический адрес</label>
                            <div className="value">{requisites.juridicalAddress}</div>

                            <label>Адрес для доставки документов</label>
                            <div className="value">{requisites.sendAddress}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>Телефон/Факс</label>
                            <div className="value phones">{requisites.phoneFAX}</div>
                        </div>
                        <div className="col-sm">
                            <label>ИНН</label>
                            <div className="value">{requisites.inn}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>КПП</label>
                            <div className="value">{requisites.kpp}</div>
                        </div>
                        <div className="col-sm">
                            <label>БИК</label>
                            <div className="value">{requisites.bik}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>Расчётный счёт</label>
                            <div className="value">{requisites.bankAccount}</div>
                        </div>
                        <div className="col-sm">
                            <label>Банк</label>
                            <div className="value">{requisites.bankName}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>Кор.счёт</label>
                            <div className="value">{requisites.bankKorAccount}</div>
                        </div>
                        <div className="col-sm">
                            <label>Код по ОКВЭД</label>
                            <div className="value">{requisites.okvedCode}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>ОГРН</label>
                            <div className="value">{requisites.ogrn}</div>
                        </div>
                        <div className="col-sm">
                            <label>Код по ОКПО</label>
                            <div className="value">{requisites.okpoCode}</div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                            <label>Способ доставки документов</label>
                            <div className="value">{requisites.courier}</div>
                        </div>
                        <div className="col-sm">

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm">
                                <button type="submit" id="change-requisites__button" className="button button-blue"
                                    onClick={this.openForm}>
                                Изменить реквизиты
                            </button>
                        </div>
                    </div>
                </div>

                <PopUp isOpen={this.state.isFormOpen} close={this.closeForm}>
                    <form id="support" onSubmit={this.handleSubmit}>
                        <div className="container">
                            <div className="row">
                                <div className="col">
                                    <h2>Смена реквизитов</h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Ваше имя</label>
                                    <input type="text" required
                                           name="name"
                                           placeholder="ФИО"
                                           defaultValue={this.state.name}
                                    />
                                </div>
                                <div className="col-md">
                                    <label>Генеральный директор</label>
                                    <input type="text" required
                                           placeholder="Генеральный директор"
                                           name="chiefFIO"
                                           defaultValue={requisites['chiefFIO']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Полное наименование</label>
                                    <input type="text" required
                                           placeholder="Полное наименование"
                                           name="fullName"
                                           defaultValue={requisites['fullName']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Юридический адрес</label>
                                    <input type="text" required
                                           placeholder="Юридический адрес"
                                           name="juridicalAddress"
                                           defaultValue={requisites['juridicalAddress']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Банк</label>
                                    <input type="text" required
                                           name="bankName"
                                           defaultValue={requisites['bankName']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>ОГРН</label>
                                    <input type="text" required
                                           placeholder="ОГРН"
                                           name="ogrn"
                                           defaultValue={requisites['ogrn']}
                                    />
                                </div>
                                <div className="col-md">
                                    <label>Телефон / факс</label>
                                    <input type="text" required
                                           placeholder="Телефон / факс"
                                           name="phoneFAX"
                                           defaultValue={requisites['phoneFAX']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>ИНН / КПП</label>
                                    <input type="text" required
                                           pattern="(\d{12}\s*\/\s*\d+|\d{12}\s*\/?\s*)"
                                           placeholder="ИНН / КПП"
                                           name="inn_kpp"
                                           defaultValue={requisites['inn'] + ' / ' + requisites['kpp']}
                                    />
                                </div>
                                <div className="col-md">
                                    <label>БИК</label>
                                    <input type="text" required
                                           placeholder="БИК"
                                           name="bik"
                                           defaultValue={requisites['bik']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Код по ОКВЭД</label>
                                    <input type="text" required
                                           placeholder="Код по ОКВЭД"
                                           name="okvedCode"
                                           defaultValue={requisites['okvedCode']}
                                    />
                                </div>
                                <div className="col-md">
                                    <label>Код по ОКПО</label>
                                    <input type="text" required
                                           placeholder="Код по ОКПО"
                                           name="okpoCode"
                                           defaultValue={requisites['okpoCode']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md">
                                    <label>Расчетный счет</label>
                                    <input type="text" required
                                           placeholder="Расчетный счет"
                                           name="bankAccount"
                                           defaultValue={requisites['bankAccount']}
                                    />
                                </div>
                                <div className="col-md">
                                    <label>Кор. счет</label>
                                    <input type="text" required
                                           placeholder="Кор. счет"
                                           name="bankKorAccount"
                                           defaultValue={requisites['bankKorAccount']}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <UploadFile label="Прикрепите документ, подтверждающий смену реквизитов"
                                                id="actFile"
                                                required={true}
                                                onChange={this.handleChangeFile}
                                                modifier="wide"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <label style={{color: '#000'}}>Наш менеджер перезвонит вам по номеру:</label>
                                    <input type="hidden" name="phone" defaultValue={this.state.phone}/>
                                    <div className="phone">
                                        {this.state.phone}
                                    </div>
                                </div>
                                <div className="col-sm">
                                    <label>Номер для оперативной связи</label>
                                    <input type="tel" name="additionalPhone"
                                           pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                                           placeholder="+7 (999) 123-45-67"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm">
                                    <button type="submit" className="button button_big button_center">
                                        Отправить заявку
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </PopUp>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваш запрос принят</div>
                    <button className="button button_big" onClick={this.closeSuccess}>Ok</button>
                </PopUp>
            </div>
        )
    }
};