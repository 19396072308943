import React from 'react';
import API from "../ApiService";
import PopUp from "../popup/PopUp";

export default class CallDetailRequest extends React.Component {

    state = {
        isOpenForm: false,
        isSuccessOpen: false,
        emails: [],
        anotherEmail: null
    };

    // componentDidMount() {
    //     API.me().then(data => {
    //         this.setState({
    //             emails: data.clientEmail.split(',').map(x => x.trim())
    //         })
    //     });
    // }

    handleSubmitForm = (e) => {
        e.preventDefault();

        let {anotherEmail} = this.state;
        let {startDate, endDate, clientsEmails} = this.props;
        const emails = anotherEmail ? [anotherEmail] : clientsEmails;

        API.statsEmail(emails, startDate, endDate).then(data => {
            this.setState({isOpenForm: false});
            this.setState({isSuccessOpen: true});
        }).catch(error => {
            console.log(error);
        });
    };

    closeForm = () => {
        this.setState({isOpenForm: false});
    };

    closeSuccessModal = () => {
        this.setState({isSuccessOpen: false});
    };

    openForm = () => {
        this.setState({isOpenForm: true})
    }

    render() {
        const {clientsEmails} = this.props;

        return (
            <div>
                <div className="loader">
                    <div className="many-data"/>
                    <div className="title">
                        Для онлайн-выгрузки много данных.
                        <div>
                            <span onClick={this.openForm}>
                                Прислать их в виде файла?
                            </span>
                        </div>
                    </div>
                </div>

                <PopUp isOpen={this.state.isOpenForm} close={this.closeForm} className="Modal Modal_Act">
                    <div className="Modal__message">
                        Запрос детализации звонков
                    </div>

                    <form className="container" onSubmit={this.handleSubmitForm}>
                        <div className="row">
                            <div className="col text-center" style={{fontSize: '12px', color: '#1C395A'}}>
                                Мы пришлем документ на ваш email:
                                <div className="email"
                                     style={{color: '#0B1F35', fontWeight: 'bold', fontSize: '19px', margin: '10px'}}>
                                    {clientsEmails.map((email, i) => <div key={i}>{email}</div>)}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{paddingTop: '30px'}}>
                                <label>
                                    Указать другой email:
                                </label>
                                <input type="email" name="emails"
                                       onChange={e => this.setState({anotherEmail: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <button type="submit" className="button button_big button_center"
                                        style={{width: '100%'}}>
                                    Отправить
                                </button>
                            </div>
                        </div>
                    </form>
                </PopUp>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccessModal}>
                    <div className="Modal__message">Ваша запрос принят</div>
                    <div className="Modal__description">
                        Детализация звонков за&nbsp;выбранный период будет отправлена на&nbsp;ваш Email
                    </div>
                    <div className="button button_big" onClick={this.closeSuccessModal}>Ok</div>
                </PopUp>
            </div>
        )
    }
};