import React from 'react';
import API from '../ApiService';
import PopUp from "../popup/PopUp";
import UploadFile from "../upload-file/UploadFile";
import Select from "../form/Select";
import Hint from "../hint/Hint";
import extractForm from "../extractForm";
import mailTemplate from "../mail/template";
import AdditionalPhone from "../form/AdditionalPhone";


export default class Compensation extends React.Component {
    state = {
        me: [],
        agreements: [],
        tickets: [''],
        isSuccessOpen: false,
    };

    componentDidMount() {
        API.agreements().then(data => {
            this.setState({
                agreements: data,
            });
        });

        API.me().then(data => {
            this.setState({
                me: data,
            });
        })
    }

    createCompensation = (e) => {
        e.preventDefault();

        let {inputs} = extractForm(e.target.id);

        API.createCompensation(inputs['org'], inputs['name'], inputs['cause'], this.state.tickets).then(data => {
            window.open(data.urlFile);
        }).catch(error => {
            console.log(error);
        })
    };

    sendMessage = (e) => {
        e.preventDefault();

        API.sendMail(
            mailTemplate(<dl>
                <dt key='dt-phone'>Номер телефона:</dt>
                <dd key='dd-phone'>{this.state.me.clientPhone}</dd>

                <dt key='dt-additionalPhone'>Другой номер телефона:</dt>
                <dd key='dd-additionalPhone'>{e.target.elements['additionalPhone'].value}</dd>

                <dt key='dt-name'>Имя:</dt>
                <dd key='dd-name'>{this.state.me.clientContact}</dd>
            </dl>),
            e.target.id,
            this.state.file
        ).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
        });
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    addTicket = () => {
        let tickets = this.state.tickets;

        tickets.push('');

        this.setState({
            tickets: tickets
        });
    };

    handleChangeTicket = (idx, e) => {
        let tickets = this.state.tickets;
        tickets[idx] = e.target.value;
    };


    handleChangeFile = (value) => {
        this.setState({file: value})
    };

    render() {
        let agreements = this.state.agreements.map((item, index) => {
            return {key: index, text: item['agreementName'], value: index}
        });

        const Ticket = ({...attr}) => {
            return (
                <div className="col-md" style={{position: 'relative'}}>
                    <label>
                        Номер заявки
                        {0 === attr.idx && <Hint>
                            Номер уточните в службе эксплуатации вашего региона
                        </Hint>}
                    </label>
                    <input type="text" required
                           name="ticket"
                           pattern="[0-9]{1,15}"
                           defaultValue={this.state.tickets[attr.idx]}
                           onChange={e => this.handleChangeTicket(attr.idx, e)}
                    />
                    {this.state.tickets.length - 1 === attr.idx &&
                    <div className="addItem" onClick={this.addTicket}>+</div>}
                </div>
            )
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h1>Заявка на компенсацию</h1>
                    </div>
                </div>

                <div className="row">
                    <div className="col">
                        <form className="box" encType="multipart/form-data" id="createCompensation"
                              onSubmit={this.createCompensation}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label>Контактное лицо</label>
                                                <input type="text" required name="name"
                                                       defaultValue={this.state.me.clientContact}/>
                                            </div>
                                            <div className="col-md">
                                                <label>Договор</label>
                                                <Select
                                                    options={agreements}
                                                    defaultValue={0}
                                                    inputName='agreement'
                                                    onChange={this.handleChangeAgreement}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <label>Организация</label>
                                                <input type="text" required name="org"
                                                       defaultValue={this.state.me.clientName}/>
                                            </div>
                                            <Ticket idx={0}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    {this.state.tickets.slice(1).map((input, idx) => (
                                        <Ticket idx={idx + 1} key={'ticket-' + idx}/>
                                    ))}
                                </div>
                                <div className="row">
                                    <div className="col-lg">
                                        <label>
                                            Причина требования компенсации
                                        </label>
                                        <label style={{float: 'left', marginTop: '10px', marginRight: '15px'}}
                                               className="checkbox">
                                            <input name="cause"
                                                   type="radio"
                                                   value="Отсутствие интернета"
                                                   defaultChecked
                                            />&nbsp;Отсутствие интернета
                                            <span className="checkmark checkmark_radio"/>
                                        </label>
                                        <label style={{float: 'left', marginTop: '10px', marginRight: '10px'}}
                                               className="checkbox">
                                            <input name="cause"
                                                   type="radio"
                                                   value="Отсутствие телефонии"
                                            />&nbsp;Отсутствие телефонии
                                            <span className="checkmark checkmark_radio"/>
                                        </label>
                                    </div>
                                    <div className="col-lg">
                                        <button className="button button_gray button_medium button_right">
                                            Сохранить заявление
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <form className="box" encType="multipart/form-data" id="compensation"
                              onSubmit={this.sendMessage}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg">
                                        <UploadFile label="Отсканируйте&nbsp;и&nbsp;прикрепите подписанное заявление"
                                                    id="actFile"
                                                    required
                                                    onChange={this.handleChangeFile}
                                        />
                                    </div>
                                    <div className="col">
                                        <AdditionalPhone clientPhone={this.state.me.clientPhone}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваша заявка на получение компенсации принята</div>
                    <div className="button button_big" onClick={this.closeSuccess}>Ok</div>
                </PopUp>
            </div>
        )
    }
};