import React from 'react';
import {Link} from "react-router-dom";
import API from "../ApiService";
import ico from './invoice@2x.png';
import ReactTable from "react-table";
import strToTime from '../strToTime'
import values from "object.values";
import moment from "moment";
import '../main.css'


export default class Invoices extends React.Component {
    state = {
        data: [],
        noDataText: 'Нет данных для показа',
    };


    componentDidMount() {
        this.setState({
            noDataText: 'Загрузка…',
        });

        const period = {
            startDate: moment().subtract(1, 'months').toDate(),
            stopDate: moment().toDate(),
        };

        API.meFinDocumentsGet(period).then(data => {
            if (!Object.values) {
                values.shim();
            }

            if (!data) {
                this.setState({
                    noDataText: 'У вас еще нет счетов',
                });
                return;
            }

            data = Object.values(data);

            data.forEach((item) => {
                item['bill'] = {
                    num: item.num,
                    type: item.type,
                    url: item.url,
                };
            });

            this.setState({
                data: data,
            });
        });
    }

    render() {
        return (
            <div className="box container invoices">
                <div className="row">
                    <div className="col">
                        <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                            Последние счета
                            <Link to="/findoc" style={{
                                float: 'right',
                                textDecoration: 'none',
                                color: '#174EBF',
                                fontSize: '13px'
                            }}>
                                Показать все →
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col">

                        <ReactTable
                            data={this.state.data}
                            columns={[
                                {
                                    Header: 'Счет',
                                    accessor: 'num',
                                    sortable: false,
                                    width: Math.round(window.innerWidth * 0.1)
                                },
                                {
                                    Header: 'Документ',
                                    accessor: 'bill',
                                    sortable: false,
                                    width: Math.round(window.innerWidth * 0.1),
                                    Cell: item => (
                                        <a href={item.value.url} target="_blank" rel="noopener noreferrer">
                                            {item.value.type}
                                        </a>
                                    )
                                },
                                {
                                    Header: 'Договор',
                                    accessor: 'agreementName',
                                    sortable: false,
                                },
                                {
                                    Header: 'Дата',
                                    accessor: 'date',
                                    // width: 90,
                                    defaultSortDesc: true,
                                    sortMethod: (a, b) => {
                                        a = strToTime(a);
                                        b = strToTime(b);

                                        if (a === b) {
                                            return 0;
                                        }
                                        return a > b ? 1 : -1;
                                    }
                                },
                                {
                                    Header: 'Сумма, ₽',
                                    accessor: 'summ',
                                    width: 60,
                                },
                            ]}

                            minRows={3}
                            maxRows={3}
                            defaultPageSize={3}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            pageText=''
                            ofText='из'
                            previousText='←'
                            nextText='→'
                            noDataText={this.state.noDataText}
                            loadingText='Загрузка…'

                            sortable={false}
                            multiSort={false}
                            defaultSorted={[
                                {
                                    id: 'date',
                                    desc: true
                                }
                            ]}
                            filterable={false}
                            resizable={false}
                        />

                    </div>
                </div>
            </div>
        )
    }
};