/* TODO добавить кэш запросов, чтобы не задрочить сервер */
import decode from 'jwt-decode';
import axios from 'axios';

const TOKEN_KEY_NAME = 'id_token';
const BASE_URL = 'https://apilk2.ptl.ru';
const MAIL_TO = 'pr@ptl.ru';

function convertPhone(phone) {
    function replaceAll(string, search, replace) {
        return string.split(search).join(replace);
    }

    phone = replaceAll(phone, '8-', '+7-');
    phone = replaceAll(phone, '8(', '+7-');
    phone = replaceAll(phone, '(', '-');
    phone = replaceAll(phone, ')', '-');

    return phone;
}

class ApiService {
    constructor(baseUrl) {
        this.axios = axios.create({
            baseURL: baseUrl || BASE_URL,
        });
    }

    login(username, password) {
        return this.axios.post('/auth', {
            username: username,
            password: password
        }).then(response => {
            this.setToken(response.data.token);
        }).catch(error => {
            throw error;
        })
    }

    loggedIn() {
        const token = this.getToken();
        return !!token && !this.isTokenExpired(token);
    }

    loginFailed() {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + 'login'.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));

        let failed = matches ? (decodeURIComponent(matches[1]) === 'failed') : undefined;

        document.cookie = `login=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;

        return failed;
    }

    isTokenExpired(token) {
        try {
            const decoded = decode(token);

            if (decoded.exp < Date.now() / 1000) {
                return true;
            } else {
                this.axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                return false;
            }
        } catch (err) {
            return false;
        }
    }

    setToken(idToken) {
        document.cookie = `${TOKEN_KEY_NAME}=${idToken};`;
    }

    getToken() {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + TOKEN_KEY_NAME.replace(/([.$?*|{}()[]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    logout() {
        document.cookie = `${TOKEN_KEY_NAME}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        localStorage.clear();
    }

    getProfile() {
        return decode(this.getToken());
    }

    me() {
        return this.axios.get('/me')
            .then(resp => {
                resp.data.clientName = resp.data.clientName || '';
                resp.data.clientEmail = resp.data.clientEmail || '';
                resp.data.clientPhone = convertPhone(resp.data.clientPhone || '');
                resp.data.clientContact = convertPhone(resp.data.clientContact || '');
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    agreements() {
        return this.axios.get('/me/agreements')
            .then(resp => {
                return resp.data || [];
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    meSettings() {
        return this.axios.get('/me/settings')
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    meFinDocumentsGet(period) {
        return this.axios.get(`/me/finDocuments`, {params: period})
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    stats(period) {
        return this.axios.get(`/me/stats`, {params: period})
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    statsFile(period, type = 'xsl') {
        return this.axios.get(`/me/stats/${type}`, {params: period})
            .then(resp => {
                return resp.data['urlFile'];
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    statsEmail(emails, fromDate, toDate) {
        return this.axios.post(`/me/stats/email/`, {
            emails: emails,
            fromDate: fromDate,
            toDate: toDate,
        })
            .then(resp => {
                return resp;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    payments(period) {
        return this.axios.get('/me/payments', {params: period})
            .then(resp => {
                return resp.data.data;
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    paymentsFile(period, type = 'xsl') {
        return this.axios.get(`/me/payments/${type}`, {params: period})
            .then(resp => {
                return resp.data['urlFile'];
            })
            .catch(err => {
                console.log(err);
            })
            ;
    }

    requisites() {
        return this.axios.get('/me/requisites')
            .then(resp => {
                if (resp.data) {
                    resp.data.phoneFAX = convertPhone(resp.data.phoneFAX);
                    resp.data.juridicalAddress = resp.data.juridicalAddress.replace('Россия,', '');
                    return resp.data;
                } else {
                    return {};
                }
            })
    }

    balance() {
        return this.axios.get('/me/balance')
            .then(resp => {
                let b = parseFloat(resp.data.balance.replace(',', '.'));
                localStorage.setItem('balance', b);
                return b;
            })
            .catch(error => {
                console.log(error);
                if (error.response.status !== 200) {
                    return null;
                }
            })
    }

    promisedPayment(sum) {
        return this.axios.get('/me/promissedPayment', {params: {paymentSumm: sum}})
            .then(resp => {
                return resp.data.msg;
            })
            .catch(error => {
                throw error.response.data.msg.promissedPayment;
            })
    }

    extServices() {
        return this.axios.get('/extServices')
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    createOrder(day, emails, sum) {
        return this.axios.post('/createOrder', { // не работает, но работает как GET, хз зачем он
            params: {
                monthDay: day,
                emails: emails,
                summ: sum
            }
        })
            .then(resp => {
                console.log(resp);
                return resp.data['urlFile'];
            })
            .catch(err => {
                console.log(err);
            });
    }

    createOrderPDF(sum) {
        return this.axios.get('/createOrderPDF', {params: {summ: sum}})
            .then(resp => {
                return resp.data['urlFile'];
            })
            .catch(err => {
                console.log(err);
            });
    }

    createPeriodicOrder(monthDay, emails, sum) {
        return this.axios.post('/createPeriodicOrder', { // get OR post - вот в чем вопрос)
            params: {
                monthDay: monthDay,
                emails: emails,
                summ: sum,
            }
        })
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    createListUsers(list) {
        return this.axios.post('/createListUsers', {
            listUsers: list
        })
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    createReturnMoney(orgName, fio, returnSumm, inn, kpp, bik, bank, korAcc, rasAcc, reciever) {
        return this.axios.post('/createReturnMoney', {
            returnMoneyData: {
                orgName: orgName,
                fio: fio,
                returnSumm: returnSumm,
                inn: inn,
                kpp: kpp,
                bik: bik,
                bank: bank,
                korAcc: korAcc,
                rasAcc: rasAcc,
                reciever: reciever
            }
        })
            .then(resp => {
                return resp.data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    createCompensation(orgName, fio, cause, ticketsNums) {
        return this.axios.post('/createCompensation',
            {
                compensationData: {
                    orgName: orgName,
                    fio: fio,
                    cause: cause,
                    ticketsNums: {
                        number: ticketsNums
                    }
                }
            }
        )

            .then(resp => {
                return resp.data;
            })

            .catch(err => {
                console.log(err);
            })
    }

    getCompareAct(period) {
        return this.axios.get('/getCompareAct', {params: period})
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                if (error.response.status === 422) {
                    throw error.response.data.msg.getCompareAct;
                }
                throw error;
            })
    }

    sendMail(messageToSend, fromForm, fileToSend, mailTo) {
        let data = new FormData();
        data.append('messageToSend', messageToSend);
        data.append('fromForm', fromForm);
        data.append('mailTo', mailTo || MAIL_TO);

        if (fileToSend) {
            let name = 'fileToSend';

            if (fileToSend instanceof Array !== true) {
                fileToSend = [fileToSend]
            }

            for (let i in fileToSend) {
                data.append(i > 0 ? name + (parseInt(i) + 1) : name, fileToSend[i], fileToSend.name);
            }
        }

        console.log(...data.entries());

        return this.axios.post('/sendMail', data)
            .then(resp => {
                return resp.data;
            })
            .catch(error => {
                console.log(error);
                if (error.response.status !== 200) {
                    return null;
                }
            })
    }

    async getAutoinfoConstructorPageData() {
        return this.axios.get('/getIVRConstructContent')
            .then(resp => {
                return [null, resp.data];
            })
            .catch(error => {
                debugger
                console.log(error)
                return [error, null]
            })
    }

    async sendAutoinfoConstructorForm(data) {
        const bodyFormData = new FormData();

        bodyFormData.set('dictors', data.dictors)
        bodyFormData.set('fonMusic', data.fonMusic)
        bodyFormData.set('fonMusicDuration', data.fonMusicDuration)
        bodyFormData.set('text', data.text)
        for (let i = 0; i < data.files.length; i++) {
            bodyFormData.append('files_' + i, data.files[i]);
        }

        console.log(bodyFormData)

        return this.axios({
            method: 'post',
            url: '/sendIVRMail',
            data: bodyFormData,
            config: {headers: {'Content-Type': 'multipart/semantic-ui-data'}}
        })
            .then(resp => {
                return [null, resp.data];
            })
            .catch(error => {
                debugger
                console.log(error)
                return [error, null]
            })
    }
}

let API = new ApiService();

export default API;
