import React from 'react';
import atsImg from "./images/ats@2x.png";
import tvImg from "./images/tv@2x.png";
import cctvImg from "./images/cctv@2x.png";
import phoneImg from "./images/phone@2x.png";
import wifiImg from "./images/wifi@2x.png";
import Service from "./Service";


export default class Services extends React.Component {

    state = {
        displayLeft: 'none',
        displayRight: 'block',
    };

    scroll = (e, to) => {
        let services = document.getElementById("services");
        let left = 0;
        const offset = 250;

        if (to === 'right') {
            left = services.scrollLeft + offset;
        }

        if (to === 'left') {
            left = services.scrollLeft - offset;
        }

        services.scroll({
            top: 0,
            left: left,
            behavior: 'smooth'
        });

        this.setState({
            displayLeft: left >= 0 ? 'block' : 'none',
            displayRight: left >= services.clientWidth ? 'none' : 'block',
        });
    };

    render() {
        return (
            <div className="container" style={{position: 'relative'}}>
                <div className="row">
                    <div className="col">
                        <h1>Дополнительные услуги</h1>
                    </div>
                </div>

                <div className="d-flex tile services" id="services">
                    <Service
                        image={tvImg}
                        title="Цифровое ТВ"
                    />

                    <Service
                        image={atsImg}
                        title="Виртуальная АТС"
                    />

                    <Service
                        title="Видеонаблюдение"
                        image={cctvImg}
                    />

                    <Service
                        title="Телефонный номер"
                        image={phoneImg}
                    />

                    <Service
                        title="Wi-Fi"
                        image={wifiImg}
                    />
                </div>

                <button onClick={(e) => this.scroll(e, 'left')}
                        className="scroll-to scroll-to_left"
                        style={{display: this.state.displayLeft}}
                />

                <button onClick={(e) => this.scroll(e, 'right')}
                        className="scroll-to scroll-to_right"
                        style={{display: this.state.displayRight}}
                />
            </div>
        )
    }
};