import React from 'react';
import PopUp from "../popup/PopUp";
import {Link} from "react-router-dom";
import './banner.css'

export default class Banner extends React.Component {

    state = {
        isSuccessOpen: false,
        isConfirmOpen: false
    };

    constructor(props) {
        super(props);

        this.exHandleConfirm = props.onConfirm ? props.onConfirm.bind(this) : function () {
            return true;
        };
    }

    handleConfirm = () => {
        this.closeConfirm();
        this.exHandleConfirm(this.props.titleText).then(() => {
            this.openSuccess();
        });
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    openConfirm = () => {
        this.setState({
            isConfirmOpen: true,
        });
    };

    closeConfirm = () => {
        this.setState({
            isConfirmOpen: false
        });
    };

    render() {
        let Button = () => {
            return (<button className="button button_gray button_big" onClick={this.openConfirm}>
                    {this.props.buttonText}
                </button>
            )
        };

        if (this.props.link) {
            Button = () => {
                return (<Link className="button button_gray button_big" to={this.props.link}>
                        {this.props.buttonText}
                    </Link>
                )
            };
        }

        return (
            <div className={'box banner' + (this.props.modifier ? ` banner_${this.props.modifier}` : '')}>
                <div className="d-flex justify-content-center align-items-center">
                    <div className="banner__cover"
                         style={this.props.image ? {backgroundImage: `url("${this.props.image}")`} : {}}>
                        &nbsp;
                    </div>
                    <div className="banner__title">
                        {this.props.titleText}
                    </div>
                    <Button/>
                </div>

                <PopUp className="Modal" isOpen={this.state.isConfirmOpen} close={this.closeConfirm}>
                    <div className="Modal__message">
                        {this.props.confirmText}
                    </div>
                    <div style={{width: '250px', margin: '0 auto'}}>
                        <button className="button button_big button_white button_left"
                                style={{margin: '15px 15px 15px 0'}}
                                onClick={this.closeConfirm}>
                            Нет
                        </button>
                        <button className="button button_big button_left"
                                style={{margin: '15px 0'}}
                                onClick={e => this.handleConfirm(e)}>
                            Ok
                        </button>
                    </div>
                </PopUp>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваш запрос принят</div>
                    <button className="button button_big" onClick={this.closeSuccess}>Ok</button>
                </PopUp>
            </div>
        )
    }
};
