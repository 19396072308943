import React from 'react';
import ReactDOMServer from 'react-dom/server';

export default function mailTemplate(content, formId) {

    return ReactDOMServer.renderToStaticMarkup(
        <div>
            {content}
        </div>
    );
};
