import './balance.css'
import dateFormat from 'dateformat'
import React from 'react';
import API from "../ApiService";
import {Link} from 'react-router-dom';
import ico from "../finances/money@2x.png";

export default class Balance extends React.Component {
    state = {
        balance: null,
        today: dateFormat(new Date(), "dd.mm.yyyy"),
    };

    componentDidMount() {
        if (API.getProfile().data.clientType === 'Promo') {
            this.getBalance()
        }
    }

    getBalance = () => {
        API.balance().then(balance => {
            this.setState({
                balance: balance.toString().split('.'),
            });
        })
    };

    render() {
        let balanceNegative = this.state.balance && this.state.balance[0] < 0 ? 'balance__value_negative' : '';

        return (this.state.balance &&
            <div className="box balance">
                <div className="row align-items-center justify-content-between">
                    <div className="col">
                        <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                            Текущий баланс
                        </div>

                        <div className="box__subtitle">
                            на {this.state.today}
                        </div>
                    </div>
                    <div className="col d-flex justify-content-center" style={{minWidth: '250px'}}>
                        <div onClick={this.getBalance} className={`balance__value ${balanceNegative} `}>
                            <span style={{fontWeight: 'bold'}}>{this.state.balance[0]}</span>
                            <span>,{this.state.balance[1]}</span> ₽
                            <span className="sync"/>
                        </div>
                    </div>
                    <div className="col">
                        <Link to="/payment" className="button button_big button_center">
                            Пополнить баланс
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
};
