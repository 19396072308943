import React from 'react';
import API from '../ApiService';
import Banner from "../banner/Banner";

export default class Edo extends React.Component {

    state = {
        edoStatus: null
    };

    componentDidMount() {
        API.requisites().then(data => {
            this.setState({edoStatus: data.edo});
        })
    }

    handleGoToEDO = (e) => {
        if (this.state.edoStatus === false) {
            return API.sendMail('Пользователь запросил переход на ЭДО', 'edo')
                .then(data => {
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            alert("У вас уже подключён переход на ЭДО");
        }
    };

    render() {
        if (this.state.edoStatus !== false) {
            return '';
        }

        return (
            <Banner
                titleText="Переходи на электронный документооборот"
                buttonText="Перейти на ЭДО"
                confirmText="Вы уверены, что хотите получать все документы в электронном виде?"
                onConfirm={this.handleGoToEDO}
            />
        )
    }
};
