import React from 'react';
import Me from "../../me/Me";
import NetworkSettings from "../NetworkSettings";
import Requisites from "../Requisites";
import Agreements from "../Agreements";
import Balance from "../../finances/Balance";
import Invoices from '../../findoc/Invoices'
import atsImg from "../../services/images/ats@2x.png";
import Service from "../../services/Service";


export default class Promo extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-4">
                        <Balance/>
                    </div>
                    <div className="col-sm">
                        <Invoices/>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Agreements/>
                    </div>
                    <div className="col-md-4">
                        <Service
                            image={atsImg}
                            modifier="box"
                            title="Виртуальная АТС"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <Me/>
                    </div>
                    <div className="col-sm-8">
                        <Requisites/>
                        <NetworkSettings/>
                    </div>
                </div>
            </div>
        )
    }
};