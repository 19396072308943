import React, {Component} from 'react';
import API from '../ApiService'
import PopUp from "../popup/PopUp";
import Hint from "../hint/Hint";
import atsImg from '../services/images/ats@2x.png'
import Service from "../services/Service";


export default class PromisedPayment extends Component {
    state = {
        message: '',
        isSuccessOpen: false,
        errorSum: false
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        API.promisedPayment(e.target.elements['sum'].value).then(data => {
            this.setState({
                isSuccessOpen: true,
                message: data,
            });
        }).catch(errorMessage => {
            this.setState({
                message: errorMessage,
                errorSum: true,
            });
        })
    };

    closeSuccessModal = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {
        return (API.getProfile().data.clientType === 'Promo'
            && localStorage.getItem('balance') < 0
            &&
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1>
                            Подключение обещанного платежа
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xl">
                        <form className="box" onSubmit={this.handleFormSubmit} id="promise-payment__form">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm">
                                        <div className="box__note">
                                            Обещанный платеж действует 3 дня. Для продолжения пользования услугами
                                            необходимо погасить долг в течение этого времени.
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-7">
                                        <label>
                                            Сумма
                                            <Hint>
                                                Максимальная сумма не более, чем абонентская плата + 500 руб.
                                            </Hint>
                                        </label>
                                        <input type="text"
                                               name="sum" min="1"
                                               pattern="\d+[.,]?\d{0,2}" required
                                               placeholder="0,00"
                                               className={this.state.errorSum ? 'error' : ''}
                                        />
                                        {this.state.errorSum &&
                                        <div className="errorInput">
                                            {this.state.message}
                                        </div>}
                                    </div>
                                    <div className="col-sm-5">
                                        <button className="button button_medium button_right" style={{float: 'right'}}>
                                            Подключить
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="col-xl">
                        <Service
                            image={atsImg}
                            modifier="wide"
                            title="Виртуальная АТС"
                        />
                    </div>
                </div>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccessModal}>
                    <div className="Modal__message">Обещанный платеж подключен</div>
                    <div className="Modal__description">{this.state.message}</div>
                    <div className="button button_big" onClick={this.closeSuccessModal}>Ok</div>
                </PopUp>
            </div>
        )
    }
};