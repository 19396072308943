import 'bootstrap/dist/css/bootstrap-grid.css';
import 'bootstrap/dist/css/bootstrap.css';
import './components/semantic-ui/sidebar.css'
import './components/semantic-ui/dropdown.css'
import './components/semantic-ui/transition.css'
import './components/main.css'
import './components/box.css'
import './components/button.css'
import './components/table/table.css'
import './components/form/form.css'
import './components/calendar/calendar.css'
import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import App from './App';
import Login from './components/login/Login';
import registerServiceWorker from './registerServiceWorker';
import smoothscroll from 'smoothscroll-polyfill'
import Modal from "react-modal";


Modal.setAppElement('#root');
smoothscroll.polyfill();

ReactDOM.render(
    <BrowserRouter>
        <Switch>
            <Route path='/login' component={Login}/>
            <Route path='/' component={App}/>
        </Switch>
    </BrowserRouter>,
    document.getElementById('root')
);

registerServiceWorker();
