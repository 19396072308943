import React from 'react';
import API from "../ApiService";
import ico from "./images/network@2x.png";
import './network-settings.css'


export default class NetworkSettings extends React.Component {
    state = {
        inet: null,
    };

    componentDidMount() {
        API.meSettings()
            .then(data => {
                const ip = Object.keys(data['inet'])[0];
                if (ip) {
                    this.setState({inet: data['inet'][ip]})
                }
            });
    }

    render() {
        const inet = this.state.inet;

        return (this.state.inet &&

            <div className="box network-settings">
                <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                    Сетевые настройки

                    <div className="collapse-trigger collapsed"
                            data-toggle="collapse"
                            data-target="#network-settings"
                            aria-expanded="false"
                            aria-controls="network-settings"/>
                </div>

                <div className="container collapse" id="network-settings">
                    <div className="row">
                        <div className="col-sm network-settings__settings">
                            <div className="row">
                                <div className="col">
                                    <label>IP</label>
                                    <div className="value">{inet.ips}</div>
                                </div>
                                <div className="col">
                                    <label>Маска сети</label>
                                    <div className="value">{inet.mask}</div>
                                </div>
                                <div className="col">
                                    <label>Шлюз</label>
                                    <div className="value">{inet.gateway}</div>
                                </div>
                                <div className="col">
                                    <label>DNS1</label>
                                    <div className="value">{inet.dns1}</div>
                                </div>
                                <div className="col">
                                    <label>DNS2</label>
                                    <div className="value">{inet.dns2}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};