import React from 'react';
import Banner from "../banner/Banner";
import compensationImg from './images/compensation@2x.png'
import refundImg from './images/refund@2x.png'
import personsImg from './images/persons@2x.png'
import movingImg from './images/moving@2x.png'
import './tile.css'


export default class ApplicationTemplates extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col">
                        <h1>Шаблоны заявлений</h1>
                    </div>
                </div>


                <div className="row">
                    <div className="col">
                        <div className="d-flex tile">
                            <Banner
                                link="/compensation"
                                image={compensationImg}
                                titleText="Компенсация"
                                modifier="narrow"
                                buttonText="Создать"
                            />

                            <Banner
                                link="/refund"
                                image={refundImg}
                                titleText="Возврат средств"
                                modifier="narrow"
                                buttonText="Создать"
                            />

                            <Banner
                                link="/persons"
                                image={personsImg}
                                titleText="Список лиц"
                                modifier="narrow"
                                buttonText="Создать"
                            />

                            <Banner
                                link="/moving"
                                image={movingImg}
                                titleText="Переезд"
                                modifier="narrow"
                                buttonText="Создать"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};