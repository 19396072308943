import React from 'react';
import {Popup} from 'semantic-ui-react'
import './download.css'

const timeoutLength = 2500;


export default class Download extends React.Component {

    state = {
        isOpen: false
    };

    handleOpen = () => {
        this.setState({isOpen: true})

        this.timeout = setTimeout(() => {
            this.setState({isOpen: false})
        }, timeoutLength)
    };

    handleClose = () => {
        this.setState({isOpen: false})
        clearTimeout(this.timeout)
    };

    render() {

        const Files = ({...attr}) => {
            return (
                <div className="download__files">
                    <button onClick={attr.getFile} name="pdf">
                        PDF
                    </button>
                    <button onClick={attr.getFile} name="xls">
                        XLS
                    </button>
                </div>
            )
        };

        return (
            <div>
                <Popup
                    content={<Files getFile={this.props.getFile}/>}
                    position='bottom right'
                    offset='7px, 0'
                    trigger={<div className="download"/>}
                    on="click"
                    onClose={this.handleClose}
                    onOpen={this.handleOpen}
                    className="download__popup"
                />
            </div>
        )
    }
};