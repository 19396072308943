import React from 'react';
import './upload.css'
import docIco from './doc@2x.png'


export default class UploadFile extends React.Component {
    state = {
        imageURI: null,
        file: null
    };

    readURI = (e) => {
        if (e.target.files && e.target.files[0]) {
            let reader = new FileReader();

            reader.onload = function (ev) {
                this.setState({
                    imageURI: ev.target.result
                });
            }.bind(this);

            reader.readAsDataURL(e.target.files[0]);
        }
    };

    handleChange = (e) => {
        this.readURI(e);

        if (this.props.onChange !== undefined) {
            this.props.onChange(e.target.files[0], e.target.id);
        }

        this.setState({
            file: e.target.files[0]
        });
    };

    render() {
        return (
            <div className={'upload' + (this.props.modifier ? ` upload_${this.props.modifier}` : '') + ' d-flex justify-content-center align-items-center flex-wrap'}>
                <input name="file"
                       type="file"
                       required={this.props.required}
                       id={this.props.id}
                       onChange={this.handleChange}
                       accept="application/msword, application/pdf, image/*"
                />

                <img alt="" src={this.state.imageURI !== null ? this.state.imageURI : docIco}/>

                <div className="d-flex align-items-center flex-wrap upload__load">
                    <label>
                        {this.props.label}
                    </label>

                    <button className="button">
                        {this.state.file ? this.state.file.name : 'Прикрепить'}
                    </button>
                </div>
            </div>
        );
    }
}
