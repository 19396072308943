import React from 'react';
import 'react-table/react-table.css'
import API from "../ApiService";
import moment from "moment";
import actImage from "./act@2x.png";
import DateRangePicker from "../calendar/DateRangePicker";


export default class CompareAct extends React.Component {
    state = {
        docLink: null,
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: moment().toDate(),
    };

    submitForm = (e) => {
        e.preventDefault();

        const period = {
            startDate: this.state.startDate,
            stopDate: this.state.endDate,
        };

        API.getCompareAct(period).then(data => {
            this.setState({
                docLink: data.url
            });
        }).catch(error => {
            console.log(error);
        });
    };

    render() {
        return (
            <div className="box">
                <div className="container">
                    <div className="row">
                        <div className="col-md text-center">
                            <img src={actImage} alt="Отчет по счетам и платежам"/>
                        </div>
                        <div className="col-md-9">
                            <form className="container" onSubmit={this.submitForm}>
                                <div className="row align-items-center">
                                    <div className="col-lg">
                                        Отчет по счетам и платежам
                                    </div>
                                    <div className="col-md" style={{paddingTop: '25px'}}>
                                        <label>Период</label>
                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            onChange={(startDate, endDate) => this.setState({
                                                startDate: startDate,
                                                endDate: endDate,
                                            })}
                                        />
                                    </div>
                                    <div className="col-md-3">
                                        <button className="button button_medium button_right">
                                            Сформировать
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this.state.docLink &&
                            <div className="row">
                                <div className="col-sm">
                                    <iframe title="Order" src={this.state.docLink} width="100%" height="720px"
                                            style={{marginTop: '20px'}}/>
                                    <a className="button button_medium" style={{maxWidth: '150px'}} target="_blank"
                                       rel="noopener noreferrer"
                                       href={this.state.docLink}
                                       download={this.state.docLink}>
                                        Сохранить
                                    </a>
                                </div>
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};