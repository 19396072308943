import './act-oroginal.css';
import dateFormat from 'dateformat';
import React from 'react';
import 'react-table/react-table.css'
import API from "../ApiService";
import moment from "moment";
import {Popup} from "semantic-ui-react";
import PopUp from "../popup/PopUp";
import DateRangePicker from "../calendar/DateRangePicker";
import Hint from "../hint/Hint";


export default class CompareActOriginal extends React.Component {

    state = {
        isOpenForm: false,
        isSuccessOpen: false,
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: moment(),
        email: null,
        anotherEmail: null,
    };

    componentDidMount() {
        API.me().then(data => {
            this.setState({
                email: data.clientEmail.split(',')[0].trim()
            })
        });
    }

    handleSubmitForm = (e) => {
        e.preventDefault();

        const email = this.state.anotherEmail || this.state.email;
        const message = `Клиент запросил акт сверки за период c ${dateFormat(this.state.startDate, 'dd.mm.yyyy')} по ${dateFormat(this.state.endDate, 'dd.mm.yyyy')} на адрес ${email}`;

        this.closeForm();

        API.sendMail(message, e.target.id).then(data => {
            this.openSuccessModal();
        }).catch(error => {
            console.log(error);
        });
    };

    openForm = () => {
        API.getCompareAct().then(data => {
            this.setState({
                isOpenForm: true
            });
        }).catch(error => {
            console.log(error);
            this.setState({
                alertMessage: error
            });
        });
    };

    closeForm = () => {
        this.setState({isOpenForm: false});
    };

    openSuccessModal = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccessModal = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {
        return (
            <div>
                {/*{this.state.alertMessage && <div className="alert" style={{paddingLeft: '65%'}}>{this.state.alertMessage}</div>}*/}
                <Popup
                    position="top right"
                    content={
                        <span style={{fontSize: '12px', whiteSpace: 'nowrap'}}>
                            Срок формирования акта сверки до 10 дней
                        </span>
                        // <span className="alert" style={{whiteSpace: 'nowrap'}}>
                        //     За актом сверки обратитесь в филиал «ПРОСТОР Телеком»
                        // </span>
                    }
                    trigger={
                        <button className="button button_right" onClick={this.openForm} style={{marginBottom: '15px'}}>
                            Оригинал акта сверки
                        </button>
                    }
                />

                <PopUp isOpen={this.state.isOpenForm}
                       close={this.closeForm}
                       className="Modal Modal_Act"
                >
                    <form className="container" onSubmit={this.handleSubmitForm}>
                        <div className="row">
                            <div className="col text-center">
                                <span className="Modal__title">
                                    Запрос оригинала акта сверки
                                </span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{paddingTop: '20px'}}>
                                <label>
                                    Период
                                    <Hint>
                                        Акт с учетом предыдущего месяца формируется не ранее 20 числа
                                    </Hint>
                                </label>
                                <DateRangePicker
                                    startDate={this.state.startDate}
                                    onChange={this.handleDateRangeChange}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center" style={{fontSize: '12px', color: '#1C395A'}}>
                                Мы пришлем скан на ваш email:

                                <div className="email"
                                     style={{color: '#0B1F35', fontWeight: 'bold', fontSize: '19px', margin: '10px'}}>
                                    {this.state.email}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col" style={{paddingTop: '30px'}}>
                                <label>
                                    Указать другой email:
                                </label>
                                <input type="email" name="emails"
                                       onChange={e => this.setState({anotherEmail: e.target.value})}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col text-center">
                                <button type="submit" className="button button_big button_center"
                                        style={{marginTop: '10px', marginBottom: '10px'}}>
                                    Отправить
                                </button>

                                <p>Оригинал акта будет выслан по почте</p>
                            </div>
                        </div>
                    </form>
                </PopUp>

                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccessModal}>
                    <div className="Modal__message">Ваша запрос принят</div>
                    <div className="Modal__description">
                        Оригинал акта сверки будет направлен на&nbsp;указанный email в&nbsp;течение 10 рабочих дней
                    </div>
                    <div className="button button_big" onClick={this.closeSuccessModal}>Ok</div>
                </PopUp>
            </div>
        )
    }
};