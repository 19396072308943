import React from 'react';
import API from "../ApiService";
import ico from "./list-line@2x.png";
import ReactTable from "react-table";
import values from "object.values";

export default class Agreements extends React.Component {
    state = {
        data: []
    };

    componentDidMount() {
        API.agreements()
            .then(data => {
                if (!Object.values) {
                    values.shim();
                }

                data = Object.values(data);

                this.setState({
                    data: data,
                });
            });
    }

    render() {
        return (!['Set', 'NEW'].includes(API.getProfile().data.clientType) &&
            <div className="box container agreements">
                <div className="row">
                    <div className="col">
                        <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                            Договоры
                        </div>

                        <ReactTable
                            data={this.state.data}
                            columns={[
                                {
                                    Header: 'Тип договора',
                                    accessor: 'agreementTariff',
                                    sortable: false,
                                },
                                {
                                    Header: 'Номер',
                                    accessor: 'agreementName',
                                    sortable: false,
                                    maxWidth: 110,
                                },
                                {
                                    Header: 'Дата',
                                    accessor: 'dateStart',
                                    defaultSortDesc: true,
                                    maxWidth: 95,
                                },
                                {
                                    Header: 'Статус',
                                    accessor: 'agreementStatus',
                                    maxWidth: 85,
                                    Cell: item => (
                                        <span
                                            style={{color: item.value.toLocaleLowerCase() === 'активен' ? '#0DBA26' : '#B6BCC3'}}>
                                            {item.value.toLocaleLowerCase()}
                                        </span>
                                    )
                                },
                            ]}

                            minRows={3}
                            maxRows={3}
                            defaultPageSize={3}
                            showPageJump={false}
                            showPageSizeOptions={false}
                            pageText=''
                            ofText='из'
                            previousText='←'
                            nextText='→'
                            noDataText={this.state.noDataText}
                            loadingText='Загрузка…'

                            sortable={false}
                            multiSort={false}
                            defaultSorted={[
                                {
                                    id: 'date',
                                    desc: true
                                }
                            ]}
                            filterable={false}
                            resizable={false}
                        />


                    </div>
                </div>
            </div>

        )
    }
};