import React, {Component} from 'react';
import API from './ApiService';

export default function withAuth(AuthComponent) {
    return class AuthWrapped extends Component {
        constructor(props) {
            super(props);
            this.state = {
                user: null
            }
        }

        componentWillMount() {
            if (!API.loggedIn()) {
                this.props.history.replace('/login')
            } else {
                try {
                    const profile = API.getProfile();
                    this.setState({
                        user: profile
                    });
                }
                catch (err) {
                    API.logout();
                    this.props.history.replace('/login')
                }
            }
        }

        render() {
            if (this.state.user) {
                return (
                    <AuthComponent history={this.props.history} user={this.state.user}/>
                )
            }
            else {
                return null
            }
        }
    }
}