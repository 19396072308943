import API from "../ApiService";
import React from 'react';
import ReactTable from 'react-table'
import DateRangePicker from "../calendar/DateRangePicker";
import moment from "moment";
import Download from "../form/Download";


function strToTime(date) {
    const d = date.split('.');
    return Math.round(new Date(`20${d[2]}/${d[1]}/${d[0]}`).getTime() / 1000)
}


export default class PaymentStatistics extends React.Component {
    state = {
        data: [],
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: moment().toDate(),
        noDataText: 'Нет данных для показа',
    };

    componentDidMount() {
        this.getData();
    }

    _getPeriod = () => {
        return {
            dateStart: moment(this.state.startDate).format('DD-MM-YYYY'),
            dateEnd: moment(this.state.endDate).format('DD-MM-YYYY'),
        };
    };

    getData = () => {
        this.setState({
            noDataText: 'Загрузка…',
            data: []
        });

        return API.payments(this._getPeriod()).then(data => {
            this.setState({
                data: data
            });
        });
    };

    handleGetFile = (e) => {
        API.paymentsFile(this._getPeriod(), e.target.name).then(url => {
            window.open(url);
        });
    };

    showStat = (e) => {
        e.preventDefault();
        this.getData();
    };

    handleDateRangeChange = (startDate, endDate) => {
        this.setState({
            startDate: startDate,
            endDate: endDate,
        });
    };

    render() {
        return (
            <div className="container payment-statistics">
                <div className="row">
                    <div className="col-sm">
                        <h1>Статистика платежей</h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm">
                        <div className="box">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-6">
                                        <label>Период</label>

                                        <DateRangePicker
                                            startDate={this.state.startDate}
                                            onChange={this.handleDateRangeChange}
                                            style={{float: 'left', marginRight: '20px'}}
                                        />

                                        <button className="button" onClick={this.showStat}>
                                            Показать
                                        </button>
                                    </div>
                                    <div className="col-sm">
                                        <Download
                                            getFile={this.handleGetFile}
                                        />
                                    </div>
                                </div>
                            </div>

                            <ReactTable
                                data={this.state.data}
                                columns={[
                                    {
                                        Header: 'Операция',
                                        accessor: 'paymentDesciption',
                                        sortable: false,
                                    },
                                    {
                                        Header: 'Дата',
                                        accessor: 'paymentDate',
                                        width: 140,
                                        sortMethod: (a, b) => {
                                            a = strToTime(a);
                                            b = strToTime(b);

                                            if (a === b) {
                                                return 0;
                                            }
                                            return a > b ? 1 : -1;
                                        }
                                    },
                                    {
                                        Header: 'Сумма, ₽',
                                        accessor: 'paymentSumm',
                                        sortable: true,
                                        width: 140,
                                        sortMethod: (a, b) => {
                                            a = parseInt(a);
                                            b = parseInt(b);

                                            if (a === b) {
                                                return 0;
                                            }
                                            return a > b ? 1 : -1;
                                        }
                                    },
                                    {
                                        Header: 'Баланс до списания, ₽',
                                        accessor: 'balanceAfter',
                                        sortable: true,
                                        width: 140,
                                    },
                                ]}

                                minRows={0}
                                defaultPageSize={30}
                                showPageJump={true}
                                showPageSizeOptions={false}

                                pageText=''
                                ofText='из'
                                previousText='←'
                                nextText='→'
                                noDataText={this.state.noDataText}
                                loadingText='Загрузка…'

                                sortable={true}
                                multiSort={false}
                                defaultSorted={[
                                    {
                                        id: 'paymentDate',
                                        desc: true
                                    }
                                ]}
                                resizable={false}
                                filterable={false}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};
