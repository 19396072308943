import React from 'react';
import '../application-templates/application-templates.css'
import Banner from "../banner/Banner";
import API from "../ApiService";


export default class Service extends React.Component {

    // componentDidMount() {
    //     API.extServices().then(data => {
    //         console.log(data);
    //     }).catch(error => {
    //         console.log(error);
    //     })
    // }

    handleConfirm = (title) => {
        return API.sendMail(
            `Пользователь запросил подключение услуги "${title}"`,
            'services'
        );
    };

    render() {
        return (
            <Banner
                image={this.props.image}
                titleText={this.props.title}
                confirmText={`Вы уверены, что хотите подключить услугу ${this.props.title}?`}
                buttonText="Подключить"
                modifier={this.props.modifier ? this.props.modifier : 'narrow'}
                onConfirm={this.handleConfirm}
            />
        )
    }
};