import 'babel-polyfill';
import 'moment/locale/ru.js';
import React, {Component} from 'react';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import ru from 'date-fns/locale/ru';
import withAuth from './components/withAuth';
import {ReactTableDefaults} from 'react-table'
import {registerLocale, setDefaultLocale} from 'react-datepicker';
import Layout from "./components/layout/Layout";
import Main from './components/main/Main';
import Services from './components/services/Services';
import Moving from './components/application-templates/Moving';
import PromisedPayment from './components/finances/PromisedPayment';
import ApplicationTemplates from './components/application-templates/ApplicationTemplates';
import Compensation from './components/application-templates/Compensation';
import Refund from './components/application-templates/Refund';
import Persons from './components/application-templates/Persons';
import Payment from "./components/finances/Payment";
import PaymentStatistics from "./components/finances/PaymentStatistics";
import CallDetail from "./components/call-detail/CallDetail";
import Pagination from "./components/table/Pagination";
import FinDoc from "./components/findoc/FinDoc";
import AutoinfoConstruct from "./components/autoinfo-construct/AutoinfoConstruct"

registerLocale('ru', ru);
setDefaultLocale('ru');

Object.assign(ReactTableDefaults, {
    PaginationComponent: Pagination,
});

class App extends Component {
    render() {
        const DefaultLayout = ({component: Component, ...rest}) => {
            return (
                <Route {...rest} render={matchProps => (
                    <Layout component={Component} {...matchProps}>
                        <Component {...matchProps} />
                    </Layout>
                )}/>
            )
        };
        return (
            <BrowserRouter>
                <Switch>
                    <DefaultLayout path='/' exact component={Main}/>
                    <DefaultLayout path="/services" component={Services}/>

                    <DefaultLayout path="/applications" component={ApplicationTemplates}/>
                    <DefaultLayout path="/moving" component={Moving}/>
                    <DefaultLayout path="/refund" component={Refund}/>
                    <DefaultLayout path="/compensation" component={Compensation}/>
                    <DefaultLayout path="/persons" component={Persons}/>

                    <DefaultLayout path="/call-detail" component={CallDetail}/>
                    <DefaultLayout path="/payment" component={Payment}/>
                    <DefaultLayout path="/payment-statistics" component={PaymentStatistics}/>
                    <DefaultLayout path="/promised-payment" component={PromisedPayment}/>
                    <DefaultLayout path="/findoc" component={FinDoc}/>
                    <DefaultLayout path="/autoinfo-construct" component={AutoinfoConstruct}/>
                </Switch>
            </BrowserRouter>
        );
    }
}

export default withAuth(App);
