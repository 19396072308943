import React from 'react';
import {NavLink} from 'react-router-dom';
import API from "../ApiService";
import './navigation.css';


const isRoot = (match, location) => {
    if (!location) return false;
    const {pathname} = location;
    return pathname === "/";
};


export default class Navigation extends React.Component {

    state = {
        type: API.getProfile().data.clientType
    };

    componentWillMount() {
        API.balance().then(balance => {
            this.setState({
                balance: balance,
            });
        });
    }

    allow = (item) => {
        let rules = {
            'agreements-menu': ['Promo', 'Corp',],
            'app-templates': ['Promo', 'Set', 'Corp',],
            'call-detail': ['Promo', 'Corp',],
            'payment-section': ['Promo', 'Corp',],
            'payment-statistics': ['Promo',],
            'payment': ['Promo',],
            'promised-payment': ['Promo',],
            'findoc': ['Promo', 'Corp',],
        };

        return rules[item] ? (rules[item].indexOf(this.state.type) > -1) : true;
    };

    render() {
        return (
            <nav className="navigation">
                {this.allow('me-menu') &&
                <NavLink className="navigation__item" activeClassName='active' id="me-menu" to="/" isActive={isRoot}>
                    Мои данные
                </NavLink>}

                <div className="navigation__section">Возможности</div>

                {this.allow('additional-services') &&
                <NavLink className="navigation__item" id="additional-services" activeClassName='active' to="/services">
                    Дополнительные услуги
                </NavLink>}

                {this.allow('app-templates') &&
                <NavLink className="navigation__item" id="app-templates" to="/applications">
                    Шаблоны заявлений
                </NavLink>}

                {this.allow('call-detail') &&
                <NavLink className="navigation__item" to="/call-detail" id="call-detail">
                    Детализация звонков
                </NavLink>}

                {this.allow('additional-services') &&
                <NavLink className="navigation__item" to="/autoinfo-construct" id="autoinfo-construct">
                    Конструктор автоинформатора
                </NavLink>}

                {this.allow('payment-section') &&
                <div>
                    <div className="navigation__section">
                        Оплата
                    </div>

                    {this.allow('payment') &&
                    <NavLink className="navigation__item" to="/payment" id="payment">
                        Пополнить счет
                    </NavLink>}

                    {this.allow('promised-payment') && this.state.balance < 0 &&
                    <NavLink className="navigation__item navigation__item_promised-payment" to="/promised-payment">
                        Обещанный платёж
                    </NavLink>}

                    {this.allow('payment-statistics') &&
                    <NavLink className="navigation__item" to="/payment-statistics" id="stat-payment">
                        Статистика платежей
                    </NavLink>}


                    {this.allow('findoc') &&
                    <NavLink className="navigation__item" id="findoc" to="/findoc">
                        Финансовые документы
                    </NavLink>}
                </div>}
            </nav>
        )
    }
};