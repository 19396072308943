import React from 'react';


export default class AdditionalPhone extends React.Component {
    render() {
        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm col-callback">
                        <label>
                            Наш менеджер перезвонит вам по номеру:
                        </label>
                        <input name="phone" defaultValue={this.props.clientPhone} type="hidden"/>
                        <div className="phone" style={{margin: '15px 0 29px 0'}}>
                            {this.props.clientPhone && this.props.clientPhone.split(',').map(phone =>
                                <div>
                                    {phone}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="row callback-additional">
                    <div className="col-lg additionalPhone">
                        <label>Номер для оперативной связи</label>
                        <input type="tel" name="additionalPhone"
                               pattern="^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$"
                               placeholder="+7 (999) 123-45-67"
                               style={{maxWidth: '200px'}}
                        />
                    </div>
                    <div className="col-lg">
                        <button className="button button_medium button_right">
                            Отправить
                        </button>
                    </div>
                </div>
            </div>
        )
    }
};