import React, {Component} from 'react';
import {Popup} from 'semantic-ui-react'
import './hint.css'


export default class Hint extends Component {

    render() {
        return (
            <Popup content={this.props.children} position='top right' offset='15px, 0' trigger={<div className="hint"/>}/>
        )
    }
};