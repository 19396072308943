import './layout.css'
import './navbar.css'
import './content.css'
import React, {Component} from 'react'
import {Link} from "react-router-dom";
import {Sidebar} from 'semantic-ui-react'
import Support from "../support/Support";
import Navigation from "../navigation/Navigation";
import API from "../ApiService";

export default class Layout extends Component {
    state = {
        sidebarVisible: false,
        data: null
    };

    constructor(props) {
        super(props);
        this.state.data = API.getProfile().data;
    }

    componentDidMount() {
        if (window.innerWidth > 1020) {
            this.handleSidebarShow();
        }
    }

    handleSidebarToggle = () => {
        this.setState({
            sidebarVisible: !this.state.sidebarVisible
        });
    };

    handleSidebarShow = () => {
        this.setState({
            sidebarVisible: true
        });
    };

    handleSidebarHide = () => {
        this.setState({
            sidebarVisible: false
        });
    };

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            if (this.props.location !== prevProps.location && window.innerWidth < 1020) {
                this.handleSidebarHide();
            }
        }
    }

    handleLogout = () => {
        API.logout();
        window.location.reload();
    };

    render() {
        const {sidebarVisible} = this.state;

        return (
            <div>
                <nav className="navbar">
                    <button className="toggle-menu" onClick={this.handleSidebarToggle}/>
                    <Link to="/" className="navbar-brand" href="/"/>
                    <Link to="/Auth" className="logout" onClick={this.handleLogout}>
                        {this.state.data.login}
                    </Link>
                </nav>

                <Sidebar.Pushable>
                    <Sidebar
                        // as={Menu}
                        animation='overlay'
                        visible={sidebarVisible}
                        width='wide'
                        // inverted
                        // vertical
                    >
                        <Navigation
                            // onClick={this.handleSidebarHide}
                        />
                    </Sidebar>

                    <Sidebar.Pusher dimmed={sidebarVisible}>
                        <div className="pad">
                            {this.props.children}
                        </div>
                    </Sidebar.Pusher>
                </Sidebar.Pushable>

                <Support/>

            </div>
        )
    }
}
