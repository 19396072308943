import React, {Component} from 'react';
import './login.css';
import API from '../ApiService';
import PopUp from '../popup/PopUp'


export default class Login extends Component {
    state = {
        isErrorOpen: false
    };

    componentWillMount() {
        if (API.loggedIn()) {
            this.goToHome();
        } else if (API.loginFailed()) {
            this.setState({isErrorOpen: true});
        }
    }

    handleChange = (e) => {
        this.setState(
            {
                [e.target.name]: e.target.value
            }
        )
    };

    handleFormSubmit = (e) => {
        e.preventDefault();

        if (this.state.username && this.state.password) {
            API.login(this.state.username, this.state.password)
                .then(res => {
                    if (API.loggedIn()) {
                        this.goToHome();
                    }
                })
                .catch(err => {
                    this.setState({isErrorOpen: true});
                })
        }
    };

    goToHome = () => {
        this.props.history.replace('/');
        window.location.reload(); // костыль?
    };

    closeErrorModal = () => {
        this.setState({isErrorOpen: false});
    };

    render() {
        return (
            <div className="box login">

                <form onSubmit={this.handleFormSubmit} style={{padding: '0 40px', margin: '30px 0'}}>
                    <label>Логин</label>
                    <input type="text" name="username" onChange={this.handleChange}/>

                    <label>Пароль</label>
                    <input type="password" name="password" onChange={this.handleChange}/>

                    <button className="button button_big" type="submit">Войти</button>
                </form>

                <PopUp className="Modal" isOpen={this.state.isErrorOpen} close={this.closeErrorModal}>
                    <div className="Modal__message">Неверный логин или пароль</div>
                    <button className="button button_big" onClick={this.closeErrorModal}>Ok</button>
                </PopUp>
            </div>
        )
    }
};