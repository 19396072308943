import React, {Component} from 'react';
import './loader.css';


export default class Loader extends Component {
    render() {
        return (
            <div id="loader" style={{'display': this.props.show ? 'block' : 'none'}}/>
        )
    }
};