import React from 'react';
import {Form} from "semantic-ui-react";


export default class Select extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            options: [
                {key: 0, text: '', value: null}
            ],
            value: typeof props.defaultValue !== 'undefined' ? props.defaultValue : null
        };

        this.onChange = props.onChange ? props.onChange.bind(this) : function () {};
    }

    handleChange = (e, {value}) => {
        this.setState({
            value: value
        });

        this.onChange(e, {value});
    };

    render() {
        return (
            <div>
                {typeof this.props.inputName !== 'undefined' &&
                <input type="hidden" name={this.props.inputName} defaultValue={this.state.value}/>
                }
                <Form.Select
                    options={this.props.options || this.state.options}
                    selection
                    onChange={this.handleChange}
                    defaultValue={typeof this.props.defaultValue !== 'undefined' ? this.props.defaultValue : null}
                />
            </div>
        )
    }
};