import React from 'react';
import API from '../ApiService';
import * as Layouts from "./types";
import Edo from "./Edo";


export default class Main extends React.Component {
    state = {
        me: {},
        type: API.getProfile().data.clientType,
    };

    componentDidMount() {
        API.me().then(data => {
            this.setState({
                me: data
            });
        });
    }

    render() {
        // console.log(API.getProfile().data.clientType);
        const Layout = Layouts[this.state.type || 'Set'] ? Layouts[this.state.type || 'Promo'] : Layouts['Set'];

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <h1>{this.state.me.clientName}</h1>
                    </div>
                </div>

                <Layout/>

                <div className="row">
                    <div className="col">
                        <Edo/>
                    </div>
                </div>
            </div>
        )
    }
};