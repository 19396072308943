import React from 'react';
import {Calendar} from "react-date-range";
import {format} from 'date-fns';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import './date-picker.css';
import './calendar.css';
import {Popup} from "semantic-ui-react";


export default class DatePicker extends React.Component {
    state = {};

    constructor(props) {
        super(props);

        this.inputName = this.props.name || 'date';

        this.additionalHandleChange = props.onChange ? props.onChange.bind(this) : function () {
            return true;
        };
    }

    handleChange = (date) => {
        this.additionalHandleChange(date, this.inputName);

        this.setState({
            date: date,
        });
    };

    render() {
        let date = this.state.date || this.props.date || new Date();

        let calendar = <Calendar
            locale={locales['ru']}
            weekdayDisplayFormat='EEEEEE'
            date={date}
            onChange={this.handleChange}
            showDateDisplay={false}
            minDate={this.props.minDate || null}
            maxDate={this.props.maxDate || null}
        />;

        const Inputs = ({...attr}) => {
            return (<div>
                    <input
                        type="text"
                        {...attr}
                    />
                </div>
            )
        };

        let inputs = <Inputs
            name={this.inputName}
            readOnly={this.props.readOnly || false}
            required={this.props.required || false}
            placeholder={format(new Date(), 'dd.MM.yyyy')}
            defaultValue={format(date, 'dd.MM.yyyy')}
        />;

        return (
            <div className="date-picker">
                <Popup content={calendar} on="click" className="calendar" offset='-35px, 23px' trigger={inputs}/>
            </div>
        )
    }
};