export default function extractForm(id) {
    let formElements = document.getElementById(id).elements;
    let valid = true;
    let inputs = {};

    for (let key in formElements) {
        if (formElements.hasOwnProperty(key)) {
            if (!formElements[key].checkValidity() && formElements[key].name !== 'file') {
                valid = false;
            }

            inputs[formElements[key].name] = formElements[key].value;
        }
    }

    return {
        valid: valid,
        inputs: inputs,
    };
}
