import React from 'react';
import Me from "../../me/Me";
import Requisites from "../Requisites";
import atsImg from "../../services/images/ats@2x.png";
import Service from "../../services/Service";


export default class Set extends React.Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-sm-8">
                        <Requisites
                            collapse={false}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Service
                            image={atsImg}
                            title="Виртуальная АТС"
                        />
                        <Me/>
                    </div>
                </div>
            </div>
        )
    }
};