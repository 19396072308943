import {format} from 'date-fns';
import React from 'react';
import API from '../ApiService';
import DatePicker from "../calendar/DatePicker";
import moment from "moment";
import UploadFile from "../upload-file/UploadFile";
import './application-templates.css'
import mailTemplate from "../mail/template";
import PopUp from "../popup/PopUp";
import AdditionalPhone from "../form/AdditionalPhone";


export default class Persons extends React.Component {

    state = {
        me: [],
        persons: [{
            passportDate: format(new Date(), 'dd.MM.yyyy')
        }],
        isSuccessOpen: false,
    };

    componentWillMount() {
        API.me().then(data => {
            this.setState({me: data});
        });
    }

    sendMessage = (e) => {
        e.preventDefault();

        API.sendMail(
            mailTemplate(<dl>
                <dt key='dt-phone'>Номер телефона:</dt>
                <dd key='dd-phone'>{this.state.me.clientPhone}</dd>

                <dt key='dt-additionalPhone'>Другой номер телефона:</dt>
                <dd key='dd-additionalPhone'>{e.target.elements['additionalPhone'].value}</dd>

                <dt key='dt-name'>Имя:</dt>
                <dd key='dd-name'>{this.state.me.clientContact}</dd>
            </dl>),
            e.target.id,
            this.state.file
        ).then(data => {
            this.openSuccess();
        }).catch(error => {
            console.log(error);
        });
    };

    createListUsers = (e) => {
        e.preventDefault();

        let list = [];

        Object.values(this.state.persons).forEach((v, i) => {
            list.push({
                fio: `${v['name']} ${v['patronymic']} ${v['surname']}`,
                passport: v['passportNumber'],
                passportDesc: ` ${v['passportDate']} ${v['passportAuthority']} ${v['passportAddress']}`
            });
        });

        API.createListUsers(list).then(data => {
            window.open(data.urlFile);
        }).catch(error => {
            console.log(error);
        });
    };

    handleChangePerson = (idx, e) => {
        let persons = this.state.persons;
        persons[idx][e.target.name] = e.target.value;
    };

    handleChangePersonPassportDate = (date, name, idx) => {
        let persons = this.state.persons;
        persons[idx][name] = format(date, 'dd.MM.yyyy');
    };

    addPerson = () => {
        let persons = this.state.persons;

        persons.push({});

        this.setState({
            persons: persons
        });
    };

    handleChangeFile = (value) => {
        this.setState({file: value})
    };

    openSuccess = () => {
        this.setState({isSuccessOpen: true});
    };

    closeSuccess = () => {
        this.setState({isSuccessOpen: false});
    };

    render() {
        const Person = ({...attr}) => {
            return (
                <div className="container" onChange={e => this.handleChangePerson(attr.idx, e)}>
                    <div className="row">
                        <div className="col-sm">
                            <label>Имя</label>
                            <input type="text" required
                                   name="name"
                                   defaultValue={this.state.persons[attr.idx]['name']}
                            />
                        </div>
                        <div className="col-sm">
                            <label>Отчество</label>
                            <input type="text" required
                                   name="patronymic"
                                   defaultValue={this.state.persons[attr.idx]['patronymic']}
                            />
                        </div>
                        <div className="col-sm">
                            <label>Фамилия</label>
                            <input type="text" required
                                   name="surname"
                                   defaultValue={this.state.persons[attr.idx]['surname']}
                            />
                        </div>
                        <div className="col-sm">
                            <label>Дата выдачи паспорта</label>
                            <DatePicker
                                name="passportDate"
                                required
                                maxDate={moment().toDate()}
                                date={moment(this.state.persons[attr.idx].passportDate || new Date(), 'DD.MM.YYYY').toDate()}
                                onChange={(e, name) => this.handleChangePersonPassportDate(e, name, attr.idx)}
                            />
                        </div>
                        <div className="col-sm">
                            <label>Серия и номер паспорта</label>
                            <input type="text" required
                                   name="passportNumber"
                                   defaultValue={this.state.persons[attr.idx]['passportNumber']}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg">
                            <label>Кем выдан паспорт</label>
                            <input type="text" required
                                   name="passportAuthority"
                                   defaultValue={this.state.persons[attr.idx]['passportAuthority']}
                            />
                        </div>
                        <div className="col-lg">
                            <label>Адрес регистрации</label>
                            <input type="text" required
                                   name="passportAddress"
                                   defaultValue={this.state.persons[attr.idx]['passportNumber']}
                            />
                        </div>
                    </div>
                </div>
            )
        };

        return (
            <div className="container">
                <div className="row">
                    <div className="col-sm">
                        <h1>
                            Список лиц, допущенных к персональной информации
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <form id="persons" onSubmit={this.createListUsers}>
                            {this.state.persons.slice(1).map((input, idx) => (
                                <div className="box" key={`box-${idx}`}>
                                    <Person idx={idx}/>
                                </div>
                            ))}
                            <div className="box">
                                <Person idx={this.state.persons.length - 1}/>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-sm">
                                            <button onClick={this.addPerson}
                                                    className="button button_white button_medium button_left">
                                                + Добавить ещё
                                            </button>
                                        </div>
                                        <div className="col-sm-3">
                                            <label>Контактное лицо</label>
                                            <input type="text" required
                                                   name="clientContact"
                                                   defaultValue={this.state.me.clientContact}
                                            />
                                        </div>
                                        <div className="col-sm-3">
                                            <button className="button button_medium button_right">
                                                Сохранить заявление
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <div className="col-10">
                                            <p style={{textAlign: 'center'}}>
                                                Распечатайте заявление на&nbsp;фирменном бланке, поставьте подписи
                                                и&nbsp;печати. Электронную версию загрузите ниже.
                                                Оригинал заявления отправьте на&nbsp;наш
                                                адрес: <b>199048, <nobr>Санкт-Петербург</nobr> Наб. Смоленки,
                                                19&ndash;21, лит. Б. <nobr>АО &laquo;Квантум&raquo;</nobr></b>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>

                        <form className="box" encType="multipart/form-data" id="persons" onSubmit={this.sendMessage}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg">
                                        <UploadFile label="Отсканируйте&nbsp;и&nbsp;прикрепите подписанное заявление"
                                                    id="actFile"
                                                    required
                                                    onChange={this.handleChangeFile}
                                        />
                                    </div>
                                    <div className="col">
                                        <AdditionalPhone clientPhone={this.state.me.clientPhone}/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <PopUp className="Modal Modal_Ok" isOpen={this.state.isSuccessOpen} close={this.closeSuccess}>
                    <div className="Modal__message">Ваша заявка на изменение списка лиц принята</div>
                    <div className="button button_big" onClick={this.closeSuccess}>Ok</div>
                </PopUp>
            </div>
        )
    }
};