import React from 'react';
import {DateRange} from "react-date-range";
import {format} from 'date-fns';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import './date-picker.css';
import './calendar.css';
import {Popup} from "semantic-ui-react";


export default class DateRangePicker extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            dateRange: {
                selection: {
                    startDate: props.startDate,
                    endDate: props.endDate || new Date(),
                    key: 'selection',
                },
            },
        };

        this.additionalHandleRangeChange = props.onChange ? props.onChange.bind(this) : function () {
            return true;
        };
    }

    handleRangeChange = (which, payload) => {
        // console.log(which, payload);

        this.additionalHandleRangeChange(payload.selection.startDate, payload.selection.endDate);

        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
    };

    render() {

        let Calendar = <DateRange
            locale={locales['ru']}
            onChange={this.handleRangeChange.bind(this, 'dateRange')}
            moveRangeOnFirstSelection={false}
            ranges={[this.state.dateRange.selection]}
            showDateDisplay={false}
            weekdayDisplayFormat='EEEEEE'
            // showSelectionPreview={false}
        />;

        let inputs = <div>
            <input
                type="text"
                readOnly
                value={format(this.state.dateRange.selection.startDate, 'dd.MM.yyyy')}
            />
            <div className="dash">
                ‒
            </div>
            <input
                type="text"
                readOnly
                value={format(this.state.dateRange.selection.endDate, 'dd.MM.yyyy')}
            />
        </div>;

        return (
            <div className="date-picker" style={this.props.style || null}>
                <Popup content={Calendar} on='click' className='calendar' offset='-35px, 23px' trigger={inputs}/>
            </div>
        )
    }
};