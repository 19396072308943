import React from 'react';
import API from '../ApiService';
import ico from "./user@2x.png";


export default class Me extends React.Component {
    state = {
        me: {
            clientContact: '',
            clientEmail: '',
            clientPhone: '',
        },
    };

    componentDidMount() {
        API.me()
            .then(data => {
                this.setState({
                    me: data
                });
            });
    }

    render() {
        return (
            <div className="box" style={{minHeight: '250px'}}>
                <div className="box__title" style={{backgroundImage: `url('${ico}')`}}>
                    Контактные данные
                </div>

                <div className="container">
                    <div className="row">
                        <div className="col-sm">
                            <label>Контактное лицо</label>
                            <div className="value">
                                {this.state.me.clientContact.split(',').map(item =>
                                    <div key={item}>
                                        {item}
                                    </div>
                                )}
                            </div>

                            <label>Электронная почта</label>
                            <div className="value">
                                {this.state.me.clientEmail.split(',').map(item =>
                                    <div key={item}>
                                        {item}
                                    </div>
                                )}
                            </div>

                            <label>Телефон</label>
                            <div className="value phones">
                                {this.state.me.clientPhone.split(',').map(item =>
                                    <div key={item}>
                                        {item}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

